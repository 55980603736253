import React, { useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { TextInput, Button, Title, Text, useTheme } from 'react-native-paper';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebase';
import { sendPasswordResetEmail } from 'firebase/auth';
import BackgroundImage from './BackgroundImage';

function PasswordReset() {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const navigate = useNavigate();
  const theme = useTheme();

  const handlePasswordReset = async () => {
    try {
      await sendPasswordResetEmail(auth, email);
      setSuccess('Password reset email sent. Please check your inbox.');
      setError('');
    } catch (error) {
      setError('Failed to send password reset email. Please try again.');
      setSuccess('');
      console.error('Error sending password reset email:', error.message);
    }
  };

  return (
    <View style={styles.container}>
      <BackgroundImage />
      <View style={styles.content}>
        <Title style={styles.title}>Reset Password</Title>
        <TextInput
          label="Email"
          value={email}
          onChangeText={setEmail}
          mode="outlined"
          style={styles.input}
        />
        {error ? <Text style={styles.error}>{error}</Text> : null}
        {success ? <Text style={styles.success}>{success}</Text> : null}
        <Button 
          mode="contained" 
          onPress={handlePasswordReset} 
          style={styles.button}
          contentStyle={styles.buttonContent}
        >
          Send Reset Email
        </Button>
        <Button
          mode="text"
          onPress={() => navigate('/login')}
          style={styles.textButton}
        >
          Back to Login
        </Button>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  content: {
    width: '100%',
    maxWidth: 400,
    alignItems: 'center',
    backgroundColor: 'rgba(30, 30, 30, 0.8)',
    padding: 20,
    borderRadius: 10,
  },
  title: {
    fontSize: 32,
    marginBottom: 20,
    color: '#ffffff',
    textAlign: 'center',
  },
  input: {
    marginBottom: 12,
    width: '100%',
    backgroundColor: '#2c2c2c',
  },
  button: {
    marginTop: 20,
    width: '80%',
    borderRadius: 25,
  },
  buttonContent: {
    height: 50,
  },
  textButton: {
    marginTop: 15,
  },
  error: {
    color: '#ff6b6b',
    marginBottom: 12,
    textAlign: 'center',
  },
  success: {
    color: '#4caf50',
    marginBottom: 12,
    textAlign: 'center',
  },
});

export default PasswordReset;