import React from 'react';
import { View, StyleSheet, ScrollView } from 'react-native';
import { Text, Title, Subheading } from 'react-native-paper';
import BackgroundImage from './BackgroundImage';
import Footer from './Footer';

function PrivacyPolicy() {
  return (
    <View style={styles.container}>
      <BackgroundImage />
      <ScrollView contentContainerStyle={styles.scrollView}>
        <View style={styles.contentWrapper}>
          <View style={styles.content}>
            <Title style={styles.title}>Privacy Policy</Title>
            <Text style={styles.text}>1. Chat messages are not retained by our server.</Text>
            <Text style={styles.text}>2. Your current chat sessions are only saved locally on your device.</Text>
            <Text style={styles.text}>3. You may delete your account and all associated data at any time via the Account Management page.</Text>
          </View>
        </View>
      </ScrollView>
      <View style={styles.footerWrapper}>
        <View style={styles.footerContent}>
          <Footer />
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  scrollView: {
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 20,
  },
  contentWrapper: {
    width: '100%',
    maxWidth: 600,
    paddingHorizontal: 10,
  },
  content: {
    backgroundColor: 'rgba(30, 30, 30, 0.8)',
    padding: 20,
    borderRadius: 10,
  },
  title: {
    fontSize: 32,
    marginBottom: 20,
    color: '#ffffff',
    textAlign: 'center',
  },
  subheading: {
    fontSize: 20,
    marginTop: 15,
    marginBottom: 10,
    color: '#ffffff',
  },
  text: {
    color: '#e0e0e0',
    marginBottom: 10,
  },
  footerWrapper: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    alignItems: 'center',
    paddingBottom: 10,
  },
  footerContent: {
    width: '100%',
    maxWidth: 400,
  },
});

export default PrivacyPolicy;