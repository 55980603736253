import React from 'react';
import { View, StyleSheet, Linking } from 'react-native';
import { Text, Title, Button } from 'react-native-paper';
import BackgroundImage from './BackgroundImage';
import Footer from './Footer';

function Contact() {
  const handleContactClick = (url) => {
    Linking.openURL(url);
  };

  return (
    <View style={styles.container}>
      <BackgroundImage />
      <View style={styles.contentWrapper}>
        <View style={styles.centeringWrapper}>
          <View style={styles.content}>
            <Title style={styles.title}>Contact Info</Title>
            <Button 
              mode="contained" 
              onPress={() => handleContactClick('mailto:loops@furgen.ai')}
              style={styles.button}
            >
              Email: loops@furgen.ai
            </Button>
            <Button 
              mode="contained" 
              onPress={() => handleContactClick('https://x.com/furchatai')}
              style={styles.button}
            >
              Twitter: @FurChatAI
            </Button>
            <Button 
              mode="contained" 
              onPress={() => Linking.openURL('https://discord.gg/HcwQqsb6nG')} 
              style={styles.button}
            >
              Join Discord Server
            </Button>
          </View>
        </View>
        <Footer />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
  },
  contentWrapper: {
    flex: 1,
    width: '100%',
    maxWidth: 400,
    justifyContent: 'space-between',
  },
  centeringWrapper: {
    flex: 1,
    justifyContent: 'center',
  },
  content: {
    width: '100%',
    alignItems: 'center',
    backgroundColor: 'rgba(30, 30, 30, 0.8)',
    padding: 10,
    borderRadius: 10,
  },
  title: {
    fontSize: 32,
    marginBottom: 20,
    color: '#ffffff',
    textAlign: 'center',
  },
  text: {
    color: '#e0e0e0',
    textAlign: 'center',
    marginBottom: 20,
  },
  button: {
    marginTop: 10,
    width: '80%',
  },
});

export default Contact;