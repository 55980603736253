import React, { useState, useEffect, useRef, useContext, useMemo, useCallback } from 'react';
import { View, Text, FlatList, TextInput, StyleSheet, Dimensions, TouchableOpacity, Modal, Platform, Alert, TouchableWithoutFeedback, ScrollView } from 'react-native';
import { Button, Switch, ActivityIndicator, SegmentedButtons } from 'react-native-paper';
import { useParams, useNavigate } from 'react-router-dom';
import { auth, db } from '../firebase';
import { getDoc, doc, updateDoc } from 'firebase/firestore';
import axios from 'axios';
import { Menu, RefreshCw, MessageSquare } from 'lucide-react';
import BackgroundImage from './BackgroundImage';
import { useBackgroundImage } from './BackgroundImageContext';
import { AppContext } from '../context/AppContext';

// Update the sanitizeInput function to handle non-string inputs
const sanitizeInput = (input) => {
  if (typeof input !== 'string') {
    console.warn('sanitizeInput received non-string input:', input);
    return '';
  }
  let sanitized = input.replace(/<[^>]*>?/gm, '');
  sanitized = sanitized.replace(/<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi, '');
  return sanitized;
};

const MAX_USER_MESSAGE_LENGTH = 300;

const ChatScreen = () => {
  const { characterId, sceneIndex } = useParams();
  const navigate = useNavigate();
  const [messages, setMessages] = useState([]);
  const [inputText, setInputText] = useState('');
  const [backgroundImages, setBackgroundImages] = useState([]);
  const [initialThumbnail, setInitialThumbnail] = useState('');
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [sfwChat, setSfwChat] = useState(false);
  const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });
  const [isLoading, setIsLoading] = useState(true);
  const [loadingMessageId, setLoadingMessageId] = useState(null);
  const [isResetDialogVisible, setIsResetDialogVisible] = useState(false);
  const flatListRef = useRef(null);
  const menuButtonRef = useRef(null);
  const { isMobile } = useBackgroundImage();
  const [windowDimensions, setWindowDimensions] = useState(Dimensions.get('window'));
  const [pendingMessage, setPendingMessage] = useState(null);
  const [isResetting, setIsResetting] = useState(false);
  const [lastSummary, setLastSummary] = useState(null);
  const [lastSummaryIndex, setLastSummaryIndex] = useState(-1);
  const [isCapReachedDialogVisible, setIsCapReachedDialogVisible] = useState(false);
  const [capReachedInfo, setCapReachedInfo] = useState(null);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [isMessageOptionsVisible, setIsMessageOptionsVisible] = useState(false);
  const [showFreeModeLimitDialog, setShowFreeModeLimitDialog] = useState(false);
  const { subscriptionStatus, isSubscriptionLoading } = useContext(AppContext);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isChatHistoryLengthExceeded, setIsChatHistoryLengthExceeded] = useState(false);
  const [maxChatHistoryLength, setMaxChatHistoryLength] = useState(null);
  const [messageLength, setMessageLength] = useState(() => {
    const storedLength = localStorage.getItem('messageLength');
    return storedLength || 'short'; // Default to 'short' initially
  });
  const [lastAction, setLastAction] = useState(null);  // Changed initial state to null
  const [isSubscriptionChecked, setIsSubscriptionChecked] = useState(false);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [isSendingMessage, setIsSendingMessage] = useState(false);
  const [showNarrativeStyle, setShowNarrativeStyle] = useState(() => {
    const storedValue = localStorage.getItem('showNarrativeStyle');
    return storedValue ? JSON.parse(storedValue) : true;
  });

  useEffect(() => {
    const initializeChat = async () => {
      await fetchInitialThumbnail();
      await loadChatFromStorage();
      setIsInitialLoading(false);
    };

    initializeChat();
  }, [characterId, sceneIndex]);

  useEffect(() => {
    if (!isSubscriptionLoading) {
      const newIsSubscribed = subscriptionStatus !== 'none';
      setIsSubscribed(newIsSubscribed);
      if (!newIsSubscribed || subscriptionStatus == 'admirer') {
        setMessageLength('short');
        localStorage.setItem('messageLength', 'short');
      } else {
        const storedLength = localStorage.getItem('messageLength');
        if (!storedLength) {
          setMessageLength('medium');
          localStorage.setItem('messageLength', 'medium');
        }
      }
      setIsSubscriptionChecked(true);
    }
  }, [subscriptionStatus, isSubscriptionLoading]);

  useEffect(() => {
    if (isSubscriptionChecked && !isInitialLoading && subscriptionStatus === 'none') {
      setShowFreeModeLimitDialog(true);
    }
  }, [subscriptionStatus, isSubscriptionChecked, isInitialLoading]);

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(Dimensions.get('window'));
    };

    Dimensions.addEventListener('change', handleResize);

    if (Platform.OS === 'web') {
      window.addEventListener('resize', handleResize);
      window.addEventListener('orientationchange', handleResize);

      const viewportMeta = document.createElement('meta');
      viewportMeta.name = 'viewport';
      viewportMeta.content = 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0';
      document.head.appendChild(viewportMeta);
    }

    return () => {
      Dimensions.removeEventListener('change', handleResize);
      if (Platform.OS === 'web') {
        window.removeEventListener('resize', handleResize);
        window.removeEventListener('orientationchange', handleResize);
        const viewportMeta = document.querySelector('meta[name="viewport"]');
        if (viewportMeta) {
          document.head.removeChild(viewportMeta);
        }
      }
    };
  }, []);

  useEffect(() => {
    fetchInitialThumbnail();
    loadChatFromStorage();
  }, [characterId, sceneIndex]);

  const scrollToBottom = () => {
    if (flatListRef.current) {
      setTimeout(() => {
        flatListRef.current.scrollToEnd({ animated: true });
      }, 100);
    }
  };

  useEffect(() => {
    if (messages.length > 0) {
      scrollToBottom();
      saveChatToStorage();
    }
  }, [messages]);

  useEffect(() => {
    if (pendingMessage) {
      sendMessage(pendingMessage, true);
      setPendingMessage(null);
    }
  }, [pendingMessage]);

  const handleContentSizeChange = () => {
    scrollToBottom();
  };

  const handleLayout = () => {
    scrollToBottom();
  };

  useEffect(() => {
    let animationInterval;
    if (loadingMessageId !== null) {
      let dots = '';
      animationInterval = setInterval(() => {
        dots = dots.length >= 3 ? '.' : dots + '.';
        setMessages(prevMessages => prevMessages.map(msg => 
          msg.id === loadingMessageId ? { ...msg, content: dots } : msg
        ));
      }, 500);
    }
    return () => clearInterval(animationInterval);
  }, [loadingMessageId]);

  const processMessageContent = (content) => {
    if (!showNarrativeStyle) return content;

    return content.replace(/\*(.*?)\*/g, (match, p1) => {
      return `__NARRATIVE_START__${p1}__NARRATIVE_END__`;
    });
  };

  const renderMessage = ({ item }) => {
    const isLoading = item.id === loadingMessageId;
    const isLastUserMessage = item === messages[messages.length - 1] && item.role === 'user';
    const processedContent = processMessageContent(item.content);

    return (
      <TouchableWithoutFeedback onPress={() => handleMessagePress(item)}>
        <View style={[
          styles.messageWrapper,
          item.role === 'user' ? styles.userMessageWrapper : styles.aiMessageWrapper,
        ]}>
          <View style={[
            styles.messageBubble,
            item.role === 'user' ? styles.userMessage : styles.aiMessage,
            isMobile && styles.mobileMessageBubble,
            isLoading && styles.loadingBubble,
            isLastUserMessage && styles.lastUserMessage
          ]}>
            <Text style={[
              styles.messageText, 
              isMobile && styles.mobileMessageText,
              isLoading && styles.loadingText
            ]}>
              {processedContent.split(/((?:__NARRATIVE_START__.*?__NARRATIVE_END__))/g).map((part, index) => {
                if (part.startsWith('__NARRATIVE_START__') && part.endsWith('__NARRATIVE_END__')) {
                  return (
                    <Text key={index} style={styles.narrativeText}>
                      {part.slice(19, -17)}
                    </Text>
                  );
                }
                return <Text key={index}>{part}</Text>;
              })}
            </Text>
          </View>
        </View>
      </TouchableWithoutFeedback>
    );
  };

  const fetchInitialThumbnail = async () => {
    try {
      const characterDoc = await getDoc(doc(db, 'chat_characters', characterId));
      if (characterDoc.exists()) {
        const characterData = characterDoc.data();
        const selectedScene = characterData.scenes[parseInt(sceneIndex)];
        const thumbnailUrl = selectedScene.thumbnail_file;
        setInitialThumbnail(thumbnailUrl);
        setBackgroundImages([thumbnailUrl]);
      }
    } catch (error) {
      console.error("Error fetching initial thumbnail:", error);
    }
  };

  const resetChat = () => {
    setIsResetDialogVisible(true);
    setIsMenuVisible(false);
  };

  const confirmResetChat = async () => {
    setIsResetting(true);
    setMessages([]);
    setLastSummary(null);
    setLastSummaryIndex(-1);
    setLastAction(null);  // Set to null for new chats
    await fetchInitialThumbnail(); // Re-fetch the initial thumbnail
    const storageKey = `chat_${characterId}_${sceneIndex}`;
    localStorage.removeItem(storageKey);
    setIsResetDialogVisible(false);
    saveChatToStorage();
    setIsResetting(false);
  };

  const saveChatToStorage = () => {
    const storageKey = `chat_${characterId}_${sceneIndex}`;
    const chatData = JSON.stringify({
      messages: messages || [],
      expressions: backgroundImages || [initialThumbnail],
      loadingMessageId,
      lastSummary,
      lastSummaryIndex,
      lastAction
    });
    localStorage.setItem(storageKey, chatData);
  };

  const loadChatFromStorage = () => {
    const storageKey = `chat_${characterId}_${sceneIndex}`;
    const storedChat = localStorage.getItem(storageKey);
    if (storedChat) {
      const { 
        messages, 
        expressions, 
        loadingMessageId: storedLoadingMessageId, 
        lastSummary: storedLastSummary,
        lastSummaryIndex: storedLastSummaryIndex,
        lastAction: storedLastAction
      } = JSON.parse(storedChat);
      setMessages(messages || []);
      setBackgroundImages(expressions && expressions.length > 0 ? expressions : [initialThumbnail]);
      setLastSummary(storedLastSummary);
      setLastSummaryIndex(storedLastSummaryIndex || -1);
      setLastAction(storedLastAction || null);
      if (storedLoadingMessageId) {
        const lastUserMessageIndex = messages.findLastIndex(msg => msg.role === 'user');
        if (lastUserMessageIndex !== -1) {
          setPendingMessage(messages[lastUserMessageIndex].content);
        }
        setMessages(prevMessages => prevMessages.filter(msg => msg.id !== storedLoadingMessageId));
      }
    } else {
      // If there's no stored chat, set initial states
      setMessages([]);
      setBackgroundImages([initialThumbnail]);
      setLastSummary(null);
      setLastSummaryIndex(-1);
      setLastAction(null);
    }
  };

  const sendMessage = async (content = null, isRetry = false) => {
    if (isSendingMessage && !isRetry) return;
    
    const messageContent = content || inputText.trim();
    if (messageContent === '') return;
    
    const truncatedContent = messageContent.slice(0, MAX_USER_MESSAGE_LENGTH);
    
    setIsSendingMessage(true);

    let newMessages = [...messages];
    if (!isRetry) {
      const userMessage = {
        content: truncatedContent,
        role: 'user',
        id: Date.now().toString(),
      };
      newMessages.push(userMessage);
    }

    const loadingMessage = {
      content: '',
      role: 'chatbot',
      id: (Date.now() + 1).toString(),
    };
    newMessages.push(loadingMessage);

    setMessages(newMessages);
    setLoadingMessageId(loadingMessage.id);
    setInputText('');

    try {
      const idToken = await auth.currentUser.getIdToken();

      // Create a copy of the messages for sending to the server
      let messagesToSend = newMessages.filter(msg => msg.id !== loadingMessage.id);

      // Safety check for the slice operation
      if (lastSummary && lastSummaryIndex > 0 && messagesToSend.length > 4) {
        const oldLength = messagesToSend.length;
        const sliceIndex = Math.min(lastSummaryIndex, messagesToSend.length - 4);
        messagesToSend = messagesToSend.slice(sliceIndex);
        //console.log(`Slice index: ${sliceIndex} lastSummaryIndex: ${lastSummaryIndex} old messagesToSend.length: ${oldLength} new messagesToSend.length: ${messagesToSend.length}`);
      }

      const requestData = {
        messages: messagesToSend.map(msg => ({
          role: msg.role,
          content: msg.content,
        })),
        characterId: characterId,
        sceneIndex: parseInt(sceneIndex),
        sfwChat: sfwChat,
        lastSummary: lastSummary,
        lastSummaryIndex: lastSummaryIndex,
        messageLength: messageLength,
        lastAction: lastAction,
      };

      const response = await axios.post(
        'https://us-central1-furgenai.cloudfunctions.net/proxy_chat_request',
        requestData,
        {
          headers: {
            'Authorization': `Bearer ${idToken}`,
            'Content-Type': 'application/json'
          }
        }
      );

      if (response.status === 200) {
        const { content: aiContent, expressions, summary, summaryIndex, action } = response.data;

        const aiResponse = {
          content: aiContent,
          role: 'assistant',
          id: loadingMessage.id,
        };

        if (summary) {
          setLastSummary(summary);
          setLastSummaryIndex(summaryIndex);
        }

        setMessages(prevMessages => 
          prevMessages.map(msg => 
            msg.id === loadingMessage.id ? aiResponse : msg
          )
        );
        setBackgroundImages(expressions || []);
        setLastAction(action);
      } else if (response.status === 403 && response.data.error && response.data.error.includes("cap reached")) {
        setCapReachedInfo({
          type: response.data.error.split(" ")[0].toLowerCase(),
          resetTime: response.data.reset_time
        });
        setIsCapReachedDialogVisible(true);
        setMessages(prevMessages => prevMessages.filter(msg => msg.id !== loadingMessage.id));
      } else if (response.status === 403 && response.data.error === "Chat history length exceeded") {
        setIsChatHistoryLengthExceeded(true);
        setMaxChatHistoryLength(response.data.max_length);
        setMessages(prevMessages => prevMessages.filter(msg => msg.id !== loadingMessage.id));
      } else {
        console.error("Unexpected error:", response.data.error);
        setMessages(prevMessages => prevMessages.filter(msg => msg.id !== loadingMessage.id));
      }
    } catch (error) {
      console.error("Error sending message:", error);
      if (error.response && error.response.status === 403) {
        if (error.response.data.error === "Chat history length exceeded") {
          setIsChatHistoryLengthExceeded(true);
          setMaxChatHistoryLength(error.response.data.max_length);
        } else if (error.response.data.error && error.response.data.error.includes("cap reached")) {
          setCapReachedInfo({
            type: error.response.data.error.split(" ")[0].toLowerCase(),
            resetTime: error.response.data.reset_time
          });
          setIsCapReachedDialogVisible(true);
        }
      }
      setMessages(prevMessages => prevMessages.filter(msg => msg.id !== loadingMessage.id));
    } finally {
      setIsSendingMessage(false);
      setLoadingMessageId(null);
      saveChatToStorage();
    }
  };

  const toggleMenu = () => {
    if (!isMenuVisible) {
      menuButtonRef.current.measureInWindow((x, y, width, height) => {
        const screenHeight = Dimensions.get('window').height;
        const menuHeight = 300; // Approximate height of the menu
        let topPosition = y - 120;
        
        // Adjust if the menu would appear off-screen
        if (topPosition + menuHeight > screenHeight) {
          topPosition = screenHeight - menuHeight - 10; // 10px padding from bottom
        }
        
        setMenuPosition({
          top: Math.max(10, topPosition), // Ensure it's not positioned above the screen
          left: x,
        });
      });
    }
    setIsMenuVisible(!isMenuVisible);
  };

  const endChat = () => {
    navigate('/chat-characters');
  };

  const { width: screenWidth, height: screenHeight } = windowDimensions;
  const imageSize = Math.min(screenWidth, screenHeight);
  const chatWidth = isMobile ? screenWidth : Math.max(imageSize, screenWidth * 0.8);
  const chatHeight = isMobile ? screenHeight * 0.5 : '100%';

  const handleMessagePress = (message) => {
    if (message === messages[messages.length - 1]) {
      setSelectedMessage(message);
      setIsMessageOptionsVisible(true);
    }
  };

  const handleDeleteMessage = () => {
    setMessages(prevMessages => prevMessages.filter(msg => msg.id !== selectedMessage.id));
    setIsMessageOptionsVisible(false);
    setSelectedMessage(null);
    saveChatToStorage();
  };

  const handleResendMessage = () => {
    sendMessage(selectedMessage.content, true);
    setIsMessageOptionsVisible(false);
    setSelectedMessage(null);
  };

  const handleSetMessageLength = (length) => {
    if (isSubscribed || length === 'short') {
      setMessageLength(length);
      localStorage.setItem('messageLength', length);
    }
  };

  const renderMessageLengthButtons = () => {
    if (!isSubscribed || subscriptionStatus === 'admirer') {
      return null;
    }

    return (
      <>
        <Text style={[styles.menuItemText, styles.messageLengthLabel]}>Message Length</Text>
        <View style={styles.messageLengthButtons}>
          <TouchableOpacity
            style={[styles.lengthButton, messageLength === 'short' && styles.selectedLengthButton]}
            onPress={() => handleSetMessageLength('short')}
          >
            <Text style={styles.lengthButtonText}>Short</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={[styles.lengthButton, messageLength === 'medium' && styles.selectedLengthButton]}
            onPress={() => handleSetMessageLength('medium')}
          >
            <Text style={styles.lengthButtonText}>Medium</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={[styles.lengthButton, messageLength === 'long' && styles.selectedLengthButton]}
            onPress={() => handleSetMessageLength('long')}
          >
            <Text style={styles.lengthButtonText}>Long</Text>
          </TouchableOpacity>
        </View>
      </>
    );
  };

  const handleSfwToggle = (value) => {
    setSfwChat(value);
    // Don't close the menu when toggling SFW
  };

  const renderMenu = () => (
    <View style={styles.menuContent}>
      <View style={styles.menuItem}>
        <Text style={styles.menuItemText}>SFW</Text>
        <Switch
          value={sfwChat}
          onValueChange={handleSfwToggle}
          color="rgba(139, 92, 246, 0.8)"
        />
      </View>
      <View style={styles.menuItem}>
        <Text style={styles.menuItemText}>Narrative Style</Text>
        <Switch
          value={showNarrativeStyle}
          onValueChange={(value) => setShowNarrativeStyle(value)}
          color="rgba(139, 92, 246, 0.8)"
        />
      </View>
      {renderMessageLengthButtons()}
      <Button 
        mode="contained" 
        onPress={() => {
          resetChat();
          toggleMenu();
        }}
        style={styles.resetChatButton}
        labelStyle={styles.resetChatButtonLabel}
        icon={() => <RefreshCw color="#fff" size={16} />}
      >
        Reset Chat
      </Button>
      <Button 
        mode="contained" 
        onPress={() => {
          endChat();
          toggleMenu();
        }}
        style={styles.endChatButton}
        labelStyle={styles.endChatButtonLabel}
      >
        Go Back
      </Button>
    </View>
  );

  useEffect(() => {
    localStorage.setItem('showNarrativeStyle', JSON.stringify(showNarrativeStyle));
  }, [showNarrativeStyle]);

  if (isInitialLoading || isSubscriptionLoading) {
    return (
      <View style={styles.loadingContainer}>
        <ActivityIndicator size="large" color="#8B5CF6" />
      </View>
    );
  }

  return (
    <View style={styles.container}>
      {!isResetting && <BackgroundImage images={backgroundImages} />}
      <View style={[
        styles.chatOuterContainer,
        isMobile ? styles.mobileChatOuterContainer : styles.desktopChatOuterContainer,
        { width: chatWidth }
      ]}>
        <View style={[
          styles.chatContainer, 
          { 
            height: chatHeight,
            maxHeight: isMobile ? chatHeight : '100%',
          }
        ]}>
          <FlatList
            ref={flatListRef}
            data={messages}
            renderItem={renderMessage}
            keyExtractor={(item) => item.id.toString()}
            onContentSizeChange={handleContentSizeChange}
            onLayout={handleLayout}
            maintainVisibleContentPosition={{ minIndexForVisible: 0 }}
          />
        </View>
        <View style={styles.inputContainer}>
          <TouchableOpacity onPress={toggleMenu} style={styles.menuButton} ref={menuButtonRef}>
            <Menu color="#fff" size={24} />
          </TouchableOpacity>
          <TextInput
            style={[styles.input, isMobile && styles.mobileInput]}
            value={inputText}
            onChangeText={(text) => setInputText(text.slice(0, MAX_USER_MESSAGE_LENGTH))}
            onSubmitEditing={() => sendMessage()}
            placeholder="Type a message..."
            placeholderTextColor="rgba(255, 255, 255, 0.5)"
            autoCorrect={false}
            autoCapitalize="none"
            spellCheck={false}
            editable={!isSendingMessage}  // NEW: Disable input when loading
            maxLength={MAX_USER_MESSAGE_LENGTH}
            {...(Platform.OS === 'web' && {
              autoComplete: 'off',
              onFocus: (e) => {
                e.target.setAttribute('autocomplete', 'off');
                e.target.style.fontSize = '16px';
              },
              onBlur: (e) => {
                e.target.style.fontSize = '';
              },
            })}
          />
          <Button 
            mode="contained" 
            onPress={() => sendMessage()}  // UPDATED: Call sendMessage without arguments 
            disabled={isSendingMessage}
            style={styles.sendButton}
            labelStyle={styles.sendButtonLabel}
          >
            {isSendingMessage ? 'Sending...' : 'Send'}
          </Button>
        </View>
      </View>
      <Modal
        visible={isMenuVisible}
        transparent={true}
        animationType="fade"
        onRequestClose={toggleMenu}
      >
        <TouchableOpacity 
          style={styles.modalOverlay} 
          activeOpacity={1} 
          onPressOut={toggleMenu}
        >
          <TouchableWithoutFeedback>
            <View style={[styles.menuContent, { 
              position: 'absolute', 
              top: menuPosition.top, 
              left: menuPosition.left,
              width: 250
            }]}>
              {renderMenu()}
            </View>
          </TouchableWithoutFeedback>
        </TouchableOpacity>
      </Modal>
      <Modal
        visible={isResetDialogVisible}
        transparent={true}
        animationType="fade"
        onRequestClose={() => setIsResetDialogVisible(false)}
      >
        <View style={styles.modalOverlay}>
          <View style={styles.modalContainer}>
            <View style={styles.modalContent}>
              <Text style={styles.modalTitle}>Reset Chat</Text>
              <Text style={styles.modalText}>
                Are you sure you want to reset the chat? This action cannot be undone.
              </Text>
              <View style={styles.modalButtonContainer}>
                <Button 
                  onPress={() => setIsResetDialogVisible(false)}
                  style={[styles.modalButton, styles.cancelButton]}
                  labelStyle={styles.modalButtonLabel}
                >
                  Cancel
                </Button>
                <Button 
                  onPress={confirmResetChat}
                  style={[styles.modalButton, styles.resetButton]}
                  labelStyle={styles.modalButtonLabel}
                >
                  Reset
                </Button>
              </View>
            </View>
          </View>
        </View>
      </Modal>
      <Modal
        visible={isCapReachedDialogVisible}
        transparent={true}
        animationType="fade"
        onRequestClose={() => setIsCapReachedDialogVisible(false)}
      >
        <View style={styles.modalOverlay}>
          <View style={styles.modalContainer}>
            <View style={styles.modalContent}>
              <Text style={styles.modalTitle}>Message Cap Reached</Text>
              <Text style={styles.modalText}>
                You've reached your {capReachedInfo?.type} message cap. The cap will reset on {capReachedInfo?.resetTime}.  You can subscribe or upgrade at <a href="https://patreon.com/furchatai" target="_blank" rel="noopener noreferrer">Patreon</a> to increase messaging limits.  
              </Text>
              <Button 
                onPress={() => setIsCapReachedDialogVisible(false)}
                style={styles.modalButton}
                labelStyle={styles.modalButtonLabel}
              >
                OK
              </Button>
            </View>
          </View>
        </View>
      </Modal>
      <Modal
        visible={isMessageOptionsVisible}
        transparent={true}
        animationType="fade"
        onRequestClose={() => setIsMessageOptionsVisible(false)}
      >
        <View style={styles.modalOverlay}>
          <View style={styles.modalContainer}>
            <View style={styles.modalContent}>
              <Text style={styles.modalTitle}>Message Options</Text>
              {selectedMessage && selectedMessage.role === 'user' && (
                <Button 
                  onPress={handleResendMessage}
                  style={[styles.modalButton, styles.resendButton]}
                  labelStyle={styles.modalButtonLabel}
                >
                  Resend
                </Button>
              )}
              <Button 
                onPress={handleDeleteMessage}
                style={[styles.modalButton, styles.deleteButton]}
                labelStyle={styles.modalButtonLabel}
              >
                Delete
              </Button>
              <Button 
                onPress={() => setIsMessageOptionsVisible(false)}
                style={[styles.modalButton, styles.cancelButton]}
                labelStyle={styles.modalButtonLabel}
              >
                Cancel
              </Button>
            </View>
          </View>
        </View>
      </Modal>
      <Modal
        visible={showFreeModeLimitDialog && isSubscriptionChecked}
        transparent={true}
        animationType="fade"
        onRequestClose={() => setShowFreeModeLimitDialog(false)}
      >
        <View style={styles.modalOverlay}>
          <View style={styles.modalContainer}>
            <View style={styles.modalContent}>
              <Text style={styles.modalTitle}>Free Mode Limitations</Text>
              <Text style={styles.modalText}>
                In free mode, there's a daily limit to the number of messages you can send, and only short message lengths are available. Please subscribe via <a href="https://patreon.com/furchatai" target="_blank" rel="noopener noreferrer">Patreon</a> for increased message limits and longer message options.
              </Text>
              <Button 
                onPress={() => setShowFreeModeLimitDialog(false)}
                style={styles.modalButton}
                labelStyle={styles.modalButtonLabel}
              >
                Got it
              </Button>
            </View>
          </View>
        </View>
      </Modal>
      <Modal
        visible={isChatHistoryLengthExceeded}
        transparent={true}
        animationType="fade"
        onRequestClose={() => setIsChatHistoryLengthExceeded(false)}
      >
        <View style={styles.modalOverlay}>
          <View style={styles.modalContainer}>
            <View style={styles.modalContent}>
              <Text style={styles.modalTitle}>Chat History Limit Reached</Text>
              <Text style={styles.modalText}>
                You've reached the maximum chat history length of {maxChatHistoryLength} messages for your subscription tier. Please reset the chat or upgrade your subscription to continue.
              </Text>
              <View style={styles.modalButtonContainer}>
                <Button 
                  onPress={() => setIsChatHistoryLengthExceeded(false)}
                  style={[styles.modalButton, styles.cancelButton]}
                  labelStyle={styles.modalButtonLabel}
                >
                  Close
                </Button>
                <Button 
                  onPress={() => {
                    setIsChatHistoryLengthExceeded(false);
                    resetChat();
                  }}
                  style={[styles.modalButton, styles.resetButton]}
                  labelStyle={styles.modalButtonLabel}
                >
                  Reset Chat
                </Button>
              </View>
            </View>
          </View>
        </View>
      </Modal>
    </View>
  );
};


const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'transparent',
  },
  chatOuterContainer: {
    flex: 1,
    zIndex: 1,
  },
  resetChatButton: {
    backgroundColor: 'rgba(59, 130, 246, 0.8)',
    marginBottom: 10,
  },
  resetChatButtonLabel: {
    color: '#fff',
  },
  desktopChatOuterContainer: {
    alignSelf: 'center',
  },
  chatContainer: {
    flex: 1,
    overflow: 'hidden',
  },
  mobileChatOuterContainer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.0)',
  },
  messageWrapper: {
    width: '100%',
    marginVertical: 5,
    paddingHorizontal: 10,
  },
  userMessageWrapper: {
    alignItems: 'flex-end',
  },
  aiMessageWrapper: {
    alignItems: 'flex-start',
  },
  messageBubble: {
    maxWidth: '35%',
    padding: 10,
    borderRadius: 20,
    minWidth: 60,  // Add this line
    minHeight: 40, // Add this line
  },
  mobileMessageBubble: {
    maxWidth: '90%',
  },
  userMessage: {
    backgroundColor: 'rgba(50, 50, 100, 0.66)',
  },
  aiMessage: {
    backgroundColor: 'rgba(60, 60, 60, 0.75)',
  },
  loadingBubble: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  messageText: {
    color: '#fff',
    fontWeight: 'regular',
    textShadow: '1px 1px 1px rgba(0, 0, 0, 1.0), 1px -1px 1px rgba(0, 0, 0, 1.0), -1px -1px 1px rgba(0, 0, 0, 1.0), -1px 1px 1px rgba(0, 0, 0, 1.0)',
  },
  mobileMessageText: {
    fontSize: 18,
  },
  loadingText: {
    minWidth: 40,  // Add this line
    textAlign: 'center', // Add this line
  },
  inputContainer: {
    flexDirection: 'row',
    paddingHorizontal: 10,
    paddingVertical: 5,
    alignItems: 'center',
    justifyContent: 'space-between',
    zIndex: 1,
  },
  menuButton: {
    padding: 10,
  },
  input: {
    flex: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    borderRadius: 25,
    paddingHorizontal: 20,
    paddingVertical: 10,
    marginHorizontal: 10,
    color: '#fff',
    textShadow: '1px 1px 1px rgba(0, 0, 0, 1.0), 1px -1px 1px rgba(0, 0, 0, 1.0), -1px -1px 1px rgba(0, 0, 0, 1.0), -1px 1px 1px rgba(0, 0, 0, 1.0)',
    fontSize: 16,
    ...(Platform.OS === 'web' && {
      fontSize: 16,
    }),
  },
  mobileInput: {
    fontSize: 16,
  },
  sendButton: {
    borderRadius: 25,
    paddingHorizontal: 20,
    paddingVertical: 5,
    backgroundColor: 'rgba(139, 92, 246, 0.8)',
  },
  sendButtonLabel: {
    color: '#fff',
    fontWeight: 'bold',
  },
  modalOverlay: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalContainer: {
    width: '90%',
    maxWidth: 400,
    backgroundColor: 'rgba(30, 30, 30, 0.9)',
    borderRadius: 20,
    padding: 20,
    alignItems: 'center',
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5
  },
  modalContent: {
    width: '100%',
  },
  modalTitle: {
    fontSize: 24,
    fontWeight: 'bold',
    color: '#FFFFFF',
    marginBottom: 15,
    textAlign: 'center',
  },
  modalText: {
    fontSize: 18,
    color: '#FFFFFF',
    marginBottom: 20,
    textAlign: 'center',
    lineHeight: 24,
  },
  modalButtonContainer: {
    flexDirection: 'row',
    justifyContent: 'center', // Changed from 'space-between' to 'center'
    width: '100%',
    marginTop: 20,
  },
  modalButton: {
    minWidth: '40%', // Added minWidth instead of fixed width
    borderRadius: 10,
    marginHorizontal: 5, // Added horizontal margin for spacing between buttons
    paddingVertical: 10, // Increased vertical padding for better visibility
    borderWidth: 1, // Add border
    borderColor: '#FFFFFF', // White border for contrast
  },
  cancelButton: {
    backgroundColor: 'rgba(100, 100, 100, 0.9)', // Increased opacity for better visibility
  },
  resetButton: {
    backgroundColor: 'rgba(220, 38, 38, 0.9)', // Increased opacity for better visibility
  },
  resendButton: {
    backgroundColor: 'rgba(59, 130, 246, 0.9)', // Blue color for resend button
  },
  deleteButton: {
    backgroundColor: 'rgba(220, 38, 38, 0.9)', // Red color for delete button
  },
  modalButtonLabel: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#FFFFFF',
    textAlign: 'center', // Ensure text is centered in the button
  },
  menuContent: {
    backgroundColor: 'rgba(30, 30, 30, 0.9)',
    borderRadius: 10,
    padding: 15,
    width: 220, // Increased width to accommodate buttons
  },
  menuItem: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 15,
  },
  menuItemText: {
    color: '#fff',
    fontSize: 16,
  },
  messageLengthLabel: {
    marginBottom: 5,
  },
  messageLengthButtons: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 15,
  },
  lengthButton: {
    padding: 5,
    borderRadius: 5,
    backgroundColor: 'rgba(100, 100, 100, 0.5)',
    flex: 1,
    marginHorizontal: 2,
  },
  selectedLengthButton: {
    backgroundColor: 'rgba(139, 92, 246, 0.8)',
  },
  lengthButtonText: {
    color: '#fff',
    fontSize: 12,
    textAlign: 'center',
  },
  endChatButton: {
    backgroundColor: 'rgba(220, 38, 38, 0.8)',
  },
  endChatButtonLabel: {
    color: '#fff',
  },
  lastUserMessage: {
    borderColor: 'rgba(255, 255, 255, 0.5)',
    borderWidth: 1,
  },
  deleteButton: {
    backgroundColor: '#FF4136',
  },
  disabledButton: {
    opacity: 0.5,
  },
  disabledButtonText: {
    color: 'rgba(255, 255, 255, 0.5)',
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#1E1E1E',
  },
  narrativeText: {
    fontStyle: 'italic',
    color: '#FFD700', // Gold color for narrative text
  },
});


export default ChatScreen;