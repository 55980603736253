import React, { useState, useContext, useMemo, useRef, useEffect, useCallback } from 'react';
import { View, Text, Image, FlatList, TouchableOpacity, Modal, StyleSheet, Dimensions, ActivityIndicator, ScrollView } from 'react-native';
import { Button, Provider as PaperProvider, TextInput, SegmentedButtons } from 'react-native-paper';
import { useNavigate } from 'react-router-dom';
import { useSubscription, canAccessChat, canCustomizeCharacters } from './UserSubscription';
import BackgroundImage from './BackgroundImage';
import { useBackgroundImage } from './BackgroundImageContext';
import { AppContext } from '../context/AppContext';
import Footer from './Footer';
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { MdRefresh } from 'react-icons/md';
import { useWindowDimensions } from 'react-native';

const MAX_CHARACTER_NAME_LENGTH = 40;
const MAX_SFW_PROMPT_LENGTH = 1500;
const MAX_NSFW_PROMPT_POSTFIX_LENGTH = 500;
const MAX_SCENARIO_LENGTH = 200;

const ChatCharacterSelection = () => {
  const { chatCharacters, isCharactersLoading, user, subscriptionStatus, isSubscriptionLoading, characterOverrides, setCharacterOverrides, isOverridesLoading, fetchCharacterOverrides, isDataReady } = useContext(AppContext);
  const [selectedCharacter, setSelectedCharacter] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedSceneId, setSelectedSceneId] = useState(null);
  const [selectedSceneIndex, setSelectedSceneIndex] = useState(null);
  const [activeTab, setActiveTab] = useState('scene');
  const [displayNameOverride, setDisplayNameOverride] = useState('');
  const [safePromptOverride, setSafePromptOverride] = useState('');
  const [nsfwPromptPostfixOverride, setNsfwPromptPostfixOverride] = useState('');
  const [scenarioOverride, setScenarioOverride] = useState('');
  const [sceneImage, setSceneImage] = useState(null);
  const navigate = useNavigate();
  const { isMobile } = useBackgroundImage();
  const containerRef = useRef(null);
  const [sceneOverrides, setSceneOverrides] = useState({});

  const { width, height } = useWindowDimensions();

  const layout = useMemo(() => {
    const maxContainerWidth = Math.max(500, width * 0.95);
    const containerPadding = 20;
    const cellMargin = 10;
    const targetCellWidth = 225;
    const maxColumns = 6;

    const availableWidth = maxContainerWidth - containerPadding * 2;
    const possibleColumns = Math.floor((availableWidth + cellMargin) / (targetCellWidth + cellMargin));
    const numColumns = Math.min(possibleColumns, maxColumns);
    
    const cellWidth = targetCellWidth;
    const containerWidth = Math.min(
      (cellWidth + cellMargin) * numColumns - cellMargin + containerPadding * 2,
      maxContainerWidth
    );

    return {
      containerWidth,
      cellWidth,
      cellMargin,
      numColumns,
    };
  }, [width]);

  const sortedCharacters = useMemo(() => {
    if (!chatCharacters || !Array.isArray(chatCharacters)) {
      return [];
    }
    return [...chatCharacters].sort((a, b) => {
      const nameA = characterOverrides[a.id]?.display_name || a.display_name;
      const nameB = characterOverrides[b.id]?.display_name || b.display_name;
      return nameA.localeCompare(nameB);
    });
  }, [chatCharacters, characterOverrides]);

  const canCustomize = useMemo(() => canCustomizeCharacters(subscriptionStatus), [subscriptionStatus]);

  const saveCustomizations = useCallback(async () => {
    if (user && selectedCharacter) {
      const userDocRef = doc(db, 'users', user.email.toLowerCase());
      const customizations = {
        [`character_customizations.${selectedCharacter.id}`]: {
          display_name: displayNameOverride !== selectedCharacter.display_name ? displayNameOverride : null,
          safe_prompt: safePromptOverride !== selectedCharacter.sfw_prompt ? safePromptOverride : null,
          nsfw_prompt_postfix: nsfwPromptPostfixOverride !== selectedCharacter.nsfw_prompt_postfix ? nsfwPromptPostfixOverride : null,
          scene_overrides: Object.fromEntries(
            Object.entries(sceneOverrides).filter(([_, value]) => value.scenario !== null && value.scenario !== selectedCharacter.scenes[parseInt(_.replace('scene-', ''), 10)].scenario)
          ),
        }
      };
      
      // Remove null values
      Object.keys(customizations[`character_customizations.${selectedCharacter.id}`]).forEach(key => {
        if (customizations[`character_customizations.${selectedCharacter.id}`][key] === null) {
          delete customizations[`character_customizations.${selectedCharacter.id}`][key];
        }
      });

      if (Object.keys(customizations[`character_customizations.${selectedCharacter.id}`]).length > 0) {
        await updateDoc(userDocRef, customizations);
        console.log('Customizations saved successfully');

        // Update the context
        setCharacterOverrides(prev => ({
          ...prev,
          [selectedCharacter.id]: customizations[`character_customizations.${selectedCharacter.id}`]
        }));
      }
    }
  }, [user, selectedCharacter, displayNameOverride, safePromptOverride, nsfwPromptPostfixOverride, sceneOverrides, setCharacterOverrides]);

  const updateSceneState = useCallback((sceneId, shouldSave = true) => {
    console.log('updateSceneState called with:', { sceneId, shouldSave });
    setSelectedSceneId(sceneId);
    
    if (selectedCharacter && selectedCharacter.scenes) {
      const sceneIndex = parseInt(sceneId.replace('scene-', ''), 10);
      setSelectedSceneIndex(sceneIndex);
      const selectedScene = selectedCharacter.scenes[sceneIndex];
      if (selectedScene) {
        console.log('Setting scenario override:', sceneOverrides[sceneId]?.scenario || selectedScene.scenario || '');
        setScenarioOverride(sceneOverrides[sceneId]?.scenario || selectedScene.scenario || '');
        setSceneImage(selectedScene.thumbnail_file);
      }
      console.log('Updated scene state:', { sceneId, sceneIndex, selectedScene });
    } else {
      console.error('No character selected or no scenes available');
    }

    if (shouldSave) {
      saveCustomizations();
    }
  }, [selectedCharacter, sceneOverrides, saveCustomizations]);

  const handleCharacterSelect = useCallback(async (character) => {
    setSelectedCharacter(character);
    setModalVisible(true);
    setActiveTab('scene');

    // Reset scene-related states
    setSelectedSceneId(null);
    setSelectedSceneIndex(null);
    setScenarioOverride('');
    setSceneImage(null);
    setSceneOverrides({});

    if (character.scenes && character.scenes.length > 0) {
      const initialSceneId = 'scene-0';
      const initialSceneIndex = 0;
      const initialScene = character.scenes[initialSceneIndex];

      setSelectedSceneId(initialSceneId);
      setSelectedSceneIndex(initialSceneIndex);
      setScenarioOverride(initialScene.scenario || '');
      setSceneImage(initialScene.thumbnail_file);
    }

    // Set initial values from the character config
    setSafePromptOverride(character.sfw_prompt || '');
    setNsfwPromptPostfixOverride(character.nsfw_prompt_postfix || '');
    setDisplayNameOverride(character.display_name || '');

    if (user) {
      const userDocRef = doc(db, 'users', user.email.toLowerCase());
      const userDoc = await getDoc(userDocRef);
      if (userDoc.exists()) {
        const userData = userDoc.data();
        const characterCustomizations = userData.character_customizations?.[character.id] || {};
        setDisplayNameOverride(characterCustomizations.display_name || character.display_name || '');
        setSafePromptOverride(characterCustomizations.safe_prompt || character.sfw_prompt || '');
        setNsfwPromptPostfixOverride(characterCustomizations.nsfw_prompt_postfix || character.nsfw_prompt_postfix || '');
        setSceneOverrides(characterCustomizations.scene_overrides || {});

        // Apply scene overrides if they exist
        if (characterCustomizations.scene_overrides && character.scenes && character.scenes.length > 0) {
          const initialSceneId = 'scene-0';
          const overriddenScenario = characterCustomizations.scene_overrides[initialSceneId]?.scenario;
          if (overriddenScenario) {
            setScenarioOverride(overriddenScenario);
          }
        }
      }
    }
  }, [user]);

  const handleStartChat = useCallback(() => {
    if (user) {
      if (canAccessChat(subscriptionStatus)) {
        if (user.emailVerified) {
          if (selectedCharacter && selectedSceneIndex !== null && selectedSceneIndex !== undefined) {
            // Save customizations before starting chat
            saveCustomizations().then(() => {
              setModalVisible(false);
              navigate(`/chat/${selectedCharacter.id}/${selectedSceneIndex}`);
            });
          } else {
            console.error('No character or scene selected', { selectedCharacter, selectedSceneIndex });
          }
        } else {
          navigate('/account', { state: { emailVerificationRequired: true } });
        }
      }
    } else {
      navigate('/login');
    }
  }, [user, subscriptionStatus, selectedCharacter, selectedSceneIndex, saveCustomizations, navigate]);

  const handleCloseModal = useCallback(() => {
    // Save customizations before closing modal
    saveCustomizations().then(() => {
      setModalVisible(false);
    });
  }, [saveCustomizations]);

  const renderCharacterCell = ({ item }) => {
    const overriddenName = characterOverrides[item.id]?.display_name || item.display_name;
    return (
      <TouchableOpacity 
        style={[styles.characterCell, { width: layout.cellWidth }]} 
        onPress={() => handleCharacterSelect(item)}
      >
        <Image source={{ uri: item.thumbnail_url }} style={[styles.characterImage, { width: layout.cellWidth, height: layout.cellWidth }]} />
        <View style={styles.characterNameContainer}>
          <Text style={styles.characterName}>{overriddenName}</Text>
        </View>
      </TouchableOpacity>
    );
  };

  const handleResetField = (field) => {
    switch (field) {
      case 'scenario':
        const currentScene = selectedCharacter.scenes[selectedSceneIndex];
        setScenarioOverride(currentScene.scenario || '');
        setSceneOverrides(prev => ({
          ...prev,
          [selectedSceneId]: {
            ...prev[selectedSceneId],
            scenario: null
          }
        }));
        break;
      case 'displayName':
        setDisplayNameOverride(selectedCharacter.display_name || '');
        break;
      case 'safePrompt':
        setSafePromptOverride(selectedCharacter.sfw_prompt || '');
        break;
      case 'nsfwPromptPostfix':
        setNsfwPromptPostfixOverride(selectedCharacter.nsfw_prompt_postfix || '');
        break;
    }
  };

  const [tooltipField, setTooltipField] = useState(null);

  const renderInputWithReset = (label, value, onChangeText, field, multiline = false, maxLength) => (
    <View style={styles.inputContainer}>
      <View style={styles.inputLabelContainer}>
        <View style={styles.tooltipContainer}>
          <TouchableOpacity 
            onPress={() => canCustomize && handleResetField(field)} 
            style={[styles.resetButton, !canCustomize && styles.disabledButton]}
            onMouseEnter={() => canCustomize && setTooltipField(field)}
            onMouseLeave={() => setTooltipField(null)}
            disabled={!canCustomize}
          >
            <MdRefresh size={20} color={canCustomize ? "#FFFFFF" : "#888888"} />
          </TouchableOpacity>
          {tooltipField === field && canCustomize && (
            <View style={styles.tooltip}>
              <Text style={styles.tooltipText}>Reset</Text>
            </View>
          )}
        </View>
        <Text style={styles.inputLabel}>{label}:</Text>
        <Text style={styles.characterCount}>{value.length}/{maxLength}</Text>
      </View>
      <TextInput
        value={value}
        onChangeText={(text) => onChangeText(text.slice(0, maxLength))}
        style={[styles.input, multiline && styles.multilineInput, !canCustomize && styles.disabledInput]}
        multiline={multiline}
        numberOfLines={multiline ? 4 : 1}
        editable={canCustomize}
        maxLength={maxLength}
        WebkitTextFillColor="#FFFFFF"
        textFillColor="#FFFFFF"
      />
    </View>
  );

  const renderModalContent = () => (
    <View style={styles.modalContent}>
      <SegmentedButtons
        value={activeTab}
        onValueChange={setActiveTab}
        buttons={[
          { value: 'scene', label: 'Scene' },
          { value: 'character', label: 'Character' },
        ]}
        style={styles.segmentedButtons}
      />
      
      <ScrollView style={styles.tabContentContainer}>
        {activeTab === 'scene' && (
          <View style={styles.sceneContent}>
            {sceneImage && (
              <Image 
                source={{ uri: sceneImage }}
                style={styles.sceneImage}
                onError={() => console.error('Failed to load scene image')}
              />
            )}
            <Text style={styles.modalCharacterName}>
              {displayNameOverride || selectedCharacter?.display_name}
            </Text>
            <View style={styles.sceneSelectContainer}>
              <Text style={styles.inputLabel}>Select Scene:</Text>
              <select
                value={selectedSceneId}
                style={styles.scenePicker}
                onChange={(event) => {
                  console.log('Scene selected:', event.target.value);
                  updateSceneState(event.target.value);
                }}
              >
                {selectedCharacter?.scenes.map((scene, index) => (
                  <option 
                    key={`scene-${index}`}
                    value={`scene-${index}`}
                  >
                    {scene.display_name}
                  </option>
                ))}
              </select>
            </View>
            {renderInputWithReset('Scenario', scenarioOverride, handleScenarioChange, 'scenario', true, MAX_SCENARIO_LENGTH)}
          </View>
        )}
        
        {activeTab === 'character' && (
          <View style={styles.characterContent}>
            {renderInputWithReset('Name', displayNameOverride, setDisplayNameOverride, 'displayName', false, MAX_CHARACTER_NAME_LENGTH)}
            {renderInputWithReset('Personality', safePromptOverride, setSafePromptOverride, 'safePrompt', true, MAX_SFW_PROMPT_LENGTH)}
            {renderInputWithReset('Sexuality', nsfwPromptPostfixOverride, setNsfwPromptPostfixOverride, 'nsfwPromptPostfix', true, MAX_NSFW_PROMPT_POSTFIX_LENGTH)}
          </View>
        )}
      </ScrollView>
      
      <Button 
        mode="contained" 
        onPress={handleStartChat} 
        style={[
          styles.startChatButton,
          (!user || !canAccessChat(subscriptionStatus) || (user && !user.emailVerified)) && styles.startChatButtonDisabled
        ]}
        labelStyle={[
          styles.buttonLabel,
          (!user || !canAccessChat(subscriptionStatus) || (user && !user.emailVerified)) && styles.buttonLabelDisabled
        ]}
        disabled={!user || !canAccessChat(subscriptionStatus) || (user && !user.emailVerified)}
      >
        Start Chat
      </Button>
      {!user && (
        <Text style={styles.notificationText}>
          Please log in to access chat features.
        </Text>
      )}
      {user && !user.emailVerified && (
        <Text style={styles.notificationText}>
          Please verify your email address to access chat features. 
          Go to <TouchableOpacity onPress={() => navigate('/account')}>
            <Text style={{ color: '#4C5FA0', textDecorationLine: 'underline' }}>Settings</Text>
          </TouchableOpacity> to resend the verification email if needed.
        </Text>
      )}
      <Button 
        onPress={handleCloseModal} 
        style={styles.closeButton}
        labelStyle={styles.buttonLabel}
      >
        Close
      </Button>
    </View>
  );

  const handleScenarioChange = useCallback((text) => {
    console.log('handleScenarioChange called with:', text);
    setScenarioOverride(text);
    setSceneOverrides(prev => {
      const newOverrides = {
        ...prev,
        [selectedSceneId]: {
          ...prev[selectedSceneId],
          scenario: text !== selectedCharacter?.scenes[selectedSceneIndex]?.scenario ? text : null
        }
      };
      console.log('New sceneOverrides:', newOverrides);
      return newOverrides;
    });
  }, [selectedSceneId, selectedCharacter, selectedSceneIndex]);

  useEffect(() => {
    console.log('Effect triggered. Current state:', {
      selectedCharacter: selectedCharacter?.id,
      selectedSceneId,
      selectedSceneIndex,
      scenarioOverride,
      sceneOverrides
    });
  }, [selectedCharacter, selectedSceneId, selectedSceneIndex, scenarioOverride, sceneOverrides]);

  useEffect(() => {
    if (selectedCharacter && selectedSceneId) {
      updateSceneState(selectedSceneId, false);
    }
  }, [selectedCharacter, selectedSceneId, updateSceneState]);

  return (
    <PaperProvider>
      <View style={styles.container}>
        <BackgroundImage />
        <View style={styles.contentWrapper}>
          {!isDataReady ? (
            <View style={styles.loadingContainer}>
              <ActivityIndicator size="large" color="#FFFFFF" />
              <Text style={styles.loadingText}>Loading characters...</Text>
            </View>
          ) : (
            <View style={[
              styles.characterListContainer, 
              { 
                width: layout.containerWidth, 
                maxHeight: height * 0.85,
                paddingHorizontal: 10,
              }
            ]} ref={containerRef}>
              <FlatList
                key={`flatlist-${layout.numColumns}`}
                data={sortedCharacters}
                renderItem={renderCharacterCell}
                keyExtractor={item => item.character_id || item.id}
                numColumns={layout.numColumns}
                columnWrapperStyle={[
                  styles.columnWrapper,
                  { marginHorizontal: -layout.cellMargin / 2 }
                ]}
                contentContainerStyle={styles.characterList}
                ListEmptyComponent={<Text style={styles.emptyText}>No characters found</Text>}
              />
            </View>
          )}
        </View>
        <View style={styles.footerWrapper}>
          <View style={styles.footerContent}>
            <Footer />
          </View>
        </View>
        <Modal
          animationType="slide"
          transparent={true}
          visible={modalVisible}
          onRequestClose={handleCloseModal}
        >
          <View style={styles.modalContainer}>
            {renderModalContent()}
            {!canCustomize && (
              <Text style={styles.customizationDisabledText}>
                Character customization is available starting at the voyeur tier.
              </Text>
            )}
          </View>
        </Modal>
      </View>
    </PaperProvider>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
  },
  contentWrapper: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    padding: 10,
  },
  characterListContainer: {
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    borderRadius: 20,
    padding: 5,
    alignSelf: 'center',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-track': {
      background: 'rgba(0, 0, 0, 0.1)',
      borderRadius: '4px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: 'rgba(255, 255, 255, 0.3)',
      borderRadius: '4px',
      '&:hover': {
        background: 'rgba(255, 255, 255, 0.5)',
      },
    },
    scrollbarWidth: 'thin',
    scrollbarColor: 'rgba(255, 255, 255, 0.3) rgba(0, 0, 0, 0.1)',
  },
  characterList: {
    alignItems: 'center',
  },
  columnWrapper: {
    justifyContent: 'space-between',
  },
  characterCell: {
    alignItems: 'center',
    margin: 5,
    borderRadius: 16,
    overflow: 'hidden',
    width: 250, // Set a fixed width
  },
  characterImage: {
    width: 250, // Set a fixed width
    height: 250, // Set a fixed height
    borderRadius: 15,
  },
  characterNameContainer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.8)', // Increased opacity for better readability
    padding: 3, // Reduced padding
  },
  characterName: {
    fontSize: 20, // Increased from 14 to 20
    fontWeight: 'normal', // Changed from 'regular' to 'normal'
    textAlign: 'center',
    color: '#000000',
    textShadow: '1px 1px 1px rgba(255, 255, 255, 0.5)', // Reduced shadow for cleaner look
  },
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
  },
  modalContent: {
    backgroundColor: '#1E1E1E',
    padding: 20,
    borderRadius: 15,
    alignItems: 'center',
    width: '90%',
    maxWidth: 400,
    height: 600,
    borderWidth: 1,
    borderColor: '#333333',
  },
  segmentedButtons: {
    marginBottom: 5,
    width: '100%',
  },
  tabContentContainer: {
    flex: 1,
    width: '100%',
  },
  sceneContent: {
    width: '100%',
    alignItems: 'center',
    paddingTop: 10,
  },
  characterContent: {
    width: '100%',
    alignItems: 'center',
    paddingTop: 20,
  },
  sceneImage: {
    width: 200,
    height: 200,
    borderRadius: 5,
    marginBottom: 10,
  },
  modalCharacterName: {
    fontSize: 24,
    fontWeight: 'bold',
    color: '#FFFFFF',
    marginBottom: 15,
  },
  sceneOption: {
    padding: 12,
    marginVertical: 6,
    backgroundColor: '#2C2C2C',
    borderRadius: 8,
    width: '100%',
  },
  selectedScene: {
    backgroundColor: '#4A4A4A',
  },
  sceneOptionText: {
    fontSize: 16,
    color: '#FFFFFF',
    textAlign: 'center',
  },
  modalButton: {
    marginTop: 5,
    width: '100%',
    borderRadius: 8,
  },
  startChatButton: {
    marginTop: 20,
    width: '100%',
    backgroundColor: '#6200EE',
    borderRadius: 8,
  },
  startChatButtonDisabled: {
    backgroundColor: '#A9A9A9',
  },
  buttonLabel: {
    color: '#FFFFFF',
    fontSize: 16,
    fontWeight: 'bold',
  },
  buttonLabelDisabled: {
    color: '#E0E0E0',
  },
  closeButton: {
    marginTop: 10,
    width: '100%',
    backgroundColor: '#2C2C2C',
    borderRadius: 8,
  },
  notificationText: {
    marginTop: 10,
    textAlign: 'center',
    color: '#FF6B6B',
    fontSize: 14,
    width: '100%',
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  loadingText: {
    color: '#FFFFFF',
    fontSize: 16,
    textAlign: 'center',
  },
  emptyText: {
    color: '#FFFFFF',
    fontSize: 16,
    textAlign: 'center',
  },
  footerWrapper: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    alignItems: 'center',
    paddingBottom: 10,
  },
  footerContent: {
    width: '100%',
    maxWidth: 400,
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  loadingText: {
    color: '#FFFFFF',
    fontSize: 16,
    marginTop: 10,
  },
  modalContent: {
    backgroundColor: '#1E1E1E',
    padding: 20,
    borderRadius: 15,
    alignItems: 'center',
    width: '90%',
    maxWidth: 400,
    maxHeight: '80%',
    borderWidth: 1,
    borderColor: '#333333',
  },
  sceneSelectContainer: {
    width: '100%',
    marginBottom: 10,
  },
  scenePicker: {
    width: '100%',
    color: '#FFFFFF',
    backgroundColor: '#2C2C2C',
    padding: 10,
    borderRadius: 5,
    border: '1px solid #444444',
  },
  inputContainer: {
    width: '100%',
    marginBottom: 10,
  },
  inputLabelContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 2,
  },
  resetButton: {
    padding: 5,
    marginRight: 5,
    zIndex: 1000,
  },
  inputLabel: {
    color: '#FFFFFF',
    marginBottom: 2,
    fontSize: 12,
  },
  input: {
    backgroundColor: '#2C2C2C',
    color: '#FFFFFF',
    borderRadius: 3,
    fontSize: 14,
    borderWidth: 1,
    borderColor: '#444444',
    '& .MuiInputBaseInput': {
      padding: '0',
    },
    WebkitTextFillColor: '#FFFFFF',
    textFillColor: '#FFFFFF',
  },
  multilineInput: {
    height: 'auto',
    minHeight: 160,
    maxHeight: 200,
    paddingTop: 0,
    paddingBottom: 4,
    textAlignVertical: 'top',
    WebkitTextFillColor: '#FFFFFF',
    textFillColor: '#FFFFFF',
  },
  saveButton: {
    marginTop: 10,
    width: '100%',
    backgroundColor: '#4CAF50',
  },
  tooltipContainer: {
    position: 'relative',
    zIndex: 1000,
  },
  tooltip: {
    position: 'absolute',
    backgroundColor: 'rgba(97, 97, 97, 0.9)',
    padding: 3,
    borderRadius: 3,
    top: '80%',
    left: '70%',
    transform: [{ translateY: -40 }],
    zIndex: 1001,
    marginLeft: 5,
    whiteSpace: 'nowrap',
  },
  tooltipText: {
    color: '#FFFFFF',
    fontSize: 13,
  },
  disabledButton: {
    opacity: 0.5,
  },
  disabledInput: {
    backgroundColor: '#1A1A1A',
    color: '#888888',
  },
  customizationDisabledText: {
    color: '#FF6B6B',
    fontSize: 14,
    textAlign: 'center',
    marginTop: 10,
    width: '100%',
  },
  characterCount: {
    color: '#AAAAAA',
    fontSize: 12,
    marginLeft: 'auto',
  },
});

// Add this style to your component, outside of the StyleSheet.create
const scenePickerWebStyle = {
  WebkitTextFillColor: '#FFFFFF',
  textFillColor: '#FFFFFF',
};

export default ChatCharacterSelection;