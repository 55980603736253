import { useState, useEffect, useCallback } from 'react';
import { auth } from '../firebase';
import axios from 'axios';

const CACHE_KEY = 'subscriptionStatusCache';
const CACHE_EXPIRY = 24 * 60 * 60 * 1000; // 24 hours in milliseconds

const getCachedStatus = () => {
  const cachedData = localStorage.getItem(CACHE_KEY);
  if (cachedData) {
    const { status, timestamp, userId } = JSON.parse(cachedData);
    return { status, timestamp, userId };
  }
  return null;
};

const setCachedStatus = (status, userId) => {
  const cacheData = JSON.stringify({
    status,
    timestamp: Date.now(),
    userId
  });
  localStorage.setItem(CACHE_KEY, cacheData);
};

export const useSubscription = () => {
  const [subscriptionStatus, setSubscriptionStatus] = useState('none');
  const [isLoading, setIsLoading] = useState(true);

  const fetchSubscriptionStatus = useCallback(async (forceUpdate = false) => {
    const user = auth.currentUser;
    if (!user) {
      setSubscriptionStatus('none');
      setIsLoading(false);
      return;
    }

    const cachedData = getCachedStatus();
    if (cachedData && cachedData.userId === user.uid && !forceUpdate) {
      setSubscriptionStatus(cachedData.status);
      setIsLoading(false);

      // If cache is expired, update in background
      if (Date.now() - cachedData.timestamp >= CACHE_EXPIRY) {
        fetchSubscriptionStatus(true);
      }
      return;
    }

    setIsLoading(true);
    try {
      const idToken = await user.getIdToken();
      console.debug('Fetching subscription status.');
      const response = await axios.get('https://us-central1-furgenai.cloudfunctions.net/get_user_subscription_status', {
        headers: {
          'Authorization': `Bearer ${idToken}`
        }
      });
      const newStatus = response.data.status;
      setSubscriptionStatus(newStatus);
      setCachedStatus(newStatus, user.uid);
      console.debug('Subscription status <' + newStatus + '> fetched successfully.');
    } catch (err) {
      console.error('Error fetching subscription status:', err.message);
      // If there's an error and we have cached data, use it
      if (cachedData && cachedData.userId === user.uid) {
        setSubscriptionStatus(cachedData.status);
      }
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      fetchSubscriptionStatus();
    } else {
      setSubscriptionStatus('none');
      setIsLoading(false);
    }

    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        fetchSubscriptionStatus();
      } else {
        setSubscriptionStatus('none');
        setIsLoading(false);
      }
    });

    return unsubscribe;
  }, [fetchSubscriptionStatus]);

  return { subscriptionStatus, isLoading, refetch: () => fetchSubscriptionStatus(true) };
};

export const canAccessChat = (status) => {
  return true; //['beta', 'basic', 'vip'].includes(status);
};

export const canCustomizeCharacters = (status) => {
  return ['voyeur', 'regular', 'vip'].includes(status);
};