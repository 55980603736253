import React from 'react';
import { View, StyleSheet } from 'react-native';
import { Button, Text } from 'react-native-paper';
import { useNavigate } from 'react-router-dom';
import BackgroundImage from './BackgroundImage';

function AgeVerification({ onVerified }) {
  const navigate = useNavigate();

  const handleYes = () => {
    onVerified();
    navigate('/home');
  };

  const handleNo = () => {
    window.location.href = 'https://www.google.com';
  };

  return (
    <View style={styles.container}>
      <BackgroundImage />
      <View style={styles.content}>
        <Text style={styles.title}>Age Verification</Text>
        <Text style={styles.text}>
          This site contains mature content.
          Are you at least 18 years old?
        </Text>
        <View style={styles.buttonContainer}>
          <Button mode="contained" onPress={handleYes} style={styles.button}>
            Yes, I am 18+
          </Button>
          <Button mode="outlined" onPress={handleNo} style={styles.button}>
            No, I'm under 18
          </Button>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  content: {
    width: '100%',
    maxWidth: 400,
    alignItems: 'center',
    backgroundColor: 'rgba(30, 30, 30, 0.8)',
    padding: 20,
    borderRadius: 10,
  },
  title: {
    fontSize: 24,
    marginBottom: 20,
    color: '#ffffff',
    fontWeight: 'bold',
  },
  text: {
    marginBottom: 20,
    color: '#e0e0e0',
    textAlign: 'center',
    fontSize: 18,
  },
  buttonContainer: {
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
  },
  button: {
    marginTop: 10,
    width: '80%',
    borderRadius: 25,
  },
});

export default AgeVerification;