import React, { useState, useEffect, useContext } from 'react';
import { View, StyleSheet, Platform, TextInput as RNTextInput, Modal, ScrollView } from 'react-native';
import { Button, Text, Provider as PaperProvider, ActivityIndicator } from 'react-native-paper';
import { useNavigate, useLocation } from 'react-router-dom';
import { db, auth } from '../firebase';
import { doc, updateDoc, getDoc, deleteDoc, setDoc } from 'firebase/firestore';
import { deleteUser, sendEmailVerification } from 'firebase/auth';
import { AppContext } from '../context/AppContext';
import BackgroundImage from './BackgroundImage';
import Footer from './Footer';

const MAX_NAME_LENGTH = 50;
const MAX_DESCRIPTION_LENGTH = 300;

const CustomInput = ({ label, value, onChangeText, multiline, numberOfLines, secureTextEntry, editable, maxLength }) => (
  <View style={styles.inputContainer}>
    <Text style={styles.inputLabel}>{label}</Text>
    <RNTextInput
      value={value}
      onChangeText={onChangeText}
      style={[
        styles.input,
        multiline && { height: numberOfLines * 20, textAlignVertical: 'top' },
        !editable && styles.disabledInput
      ]}
      multiline={multiline}
      numberOfLines={numberOfLines}
      secureTextEntry={secureTextEntry}
      placeholderTextColor="#aaaaaa"
      editable={editable}
      maxLength={maxLength}
    />
    <Text style={styles.charCount}>{`${value.length}/${maxLength}`}</Text>
  </View>
);

function AccountManagement() {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isNewAccount, setIsNewAccount] = useState(false);
  const [emailVerificationSent, setEmailVerificationSent] = useState(false);
  const { user, setUser, subscriptionStatus, refetchSubscription } = useContext(AppContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [deleteConfirmation, setDeleteConfirmation] = useState('');

  useEffect(() => {
    if (user) {
      fetchUserData();
      refetchSubscription();
    }

    if (location.state) {
      setIsNewAccount(location.state.newAccount);
      setEmailVerificationSent(location.state.emailVerificationSent);
    }
  }, [user, location.state]); // Remove refetchSubscription from the dependency array

  const fetchUserData = async () => {
    if (user && user.email) {
      setIsLoading(true);
      try {
        const userEmail = user.email.toLowerCase(); // Convert email to lowercase
        const userDocRef = doc(db, 'users', userEmail);
        const userDoc = await getDoc(userDocRef);
        
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setName(userData.name || '');
          setDescription(userData.description || '');
        } else {
          // Create a new user document if it doesn't exist
          await setDoc(userDocRef, {
            name: '',
            description: '',
            email: userEmail, // Use lowercase email
            createdAt: new Date()
          });
          console.log('New user document created');
        }
      } catch (error) {
        console.error('Error fetching or creating user data:', error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleSave = async () => {
    if (user && user.email) {
      try {
        const userEmail = user.email.toLowerCase(); // Convert email to lowercase
        await updateDoc(doc(db, 'users', userEmail), {
          name: name.slice(0, MAX_NAME_LENGTH),
          description: description.slice(0, MAX_DESCRIPTION_LENGTH)
        });
        console.log('User data updated successfully');
        navigate('/');
      } catch (error) {
        console.error('Error updating user data:', error);
      }
    }
  };

  const handleResendVerificationEmail = async () => {
    if (user && !user.emailVerified) {
      try {
        await sendEmailVerification(user);
        setEmailVerificationSent(true);
      } catch (error) {
        console.error('Error resending verification email:', error);
      }
    }
  };

  const showDeleteConfirmation = () => {
    setShowDeleteModal(true);
  };

  const hideDeleteConfirmation = () => {
    setShowDeleteModal(false);
    setDeleteConfirmation('');
  };

  const handleDeleteAccount = async () => {
    if (user && deleteConfirmation === 'DELETE') {
      try {
        const userEmail = user.email.toLowerCase(); // Convert email to lowercase
        await deleteDoc(doc(db, 'users', userEmail));
        await deleteUser(user);
        console.log('Account deleted successfully');
        setUser(null);
        navigate('/');
      } catch (error) {
        console.error('Error deleting account:', error);
      }
    } else {
      console.error('Invalid deletion confirmation');
    }
    hideDeleteConfirmation();
  };

  return (
    <PaperProvider>
      <View style={styles.container}>
        <BackgroundImage />
        <View style={styles.contentWrapper}>
          <ScrollView contentContainerStyle={styles.scrollViewContent}>
            <View style={styles.content}>
              <Text style={styles.title}>Account Management</Text>
              
              {isNewAccount && (
                <Text style={styles.successMessage}>Your account has been created successfully!</Text>
              )}

              {user && (
                <View style={styles.userInfoContainer}>
                  <Text style={styles.userInfoText}>Email: {user.email}</Text>
                  <Text style={styles.userInfoText}>Subscription Status: {subscriptionStatus}</Text>
                </View>
              )}

              {user && !user.emailVerified && (
                <View style={styles.verificationContainer}>
                  <Text style={styles.warningMessage}>
                    Your email is not verified. Please check your inbox and verify your email.
                  </Text>
                  {emailVerificationSent ? (
                    <Text style={styles.successMessage}>
                      A verification email has been sent. Please check your inbox.
                    </Text>
                  ) : (
                    <Button 
                      mode="contained" 
                      onPress={handleResendVerificationEmail} 
                      style={styles.verificationButton}
                    >
                      Resend Verification Email
                    </Button>
                  )}
                </View>
              )}

              <Text style={styles.infoText}>This info will be used to inform the chatbot a little about you.</Text>
              {isLoading ? (
                <View style={styles.loadingContainer}>
                  <ActivityIndicator animating={true} color="#ffffff" size="large" />
                  <Text style={styles.loadingText}>Loading account data...</Text>
                </View>
              ) : (
                <>
                  <CustomInput
                    label="Name"
                    value={name}
                    onChangeText={setName}
                    editable={!isLoading}
                    maxLength={MAX_NAME_LENGTH}
                  />
                  <CustomInput
                    label="Description"
                    value={description}
                    onChangeText={setDescription}
                    multiline
                    numberOfLines={8}
                    editable={!isLoading}
                    maxLength={MAX_DESCRIPTION_LENGTH}
                  />
                  <Button
                    mode="contained"
                    onPress={handleSave}
                    style={styles.saveButton}
                    contentStyle={styles.buttonContent}
                    disabled={isLoading}
                  >
                    Save Changes
                  </Button>
                  <Button
                    mode="contained"
                    onPress={showDeleteConfirmation}
                    style={styles.deleteButton}
                    contentStyle={styles.buttonContent}
                    disabled={isLoading}
                  >
                    Delete Account
                  </Button>
                </>
              )}
            </View>
          </ScrollView>
          <Footer />
        </View>
        <Modal
          animationType="slide"
          transparent={true}
          visible={showDeleteModal}
          onRequestClose={hideDeleteConfirmation}
        >
          <View style={styles.modalContainer}>
            <View style={styles.modalContent}>
              <Text style={styles.modalText}>Are you sure you want to delete your account? This action cannot be undone.</Text>
              <CustomInput
                label="Type DELETE to confirm"
                value={deleteConfirmation}
                onChangeText={setDeleteConfirmation}
              />
              <Button 
                mode="contained"
                onPress={handleDeleteAccount}
                style={styles.deleteConfirmButton}
                labelStyle={styles.deleteConfirmButtonLabel}
                disabled={deleteConfirmation !== 'DELETE'}
              >
                Delete Account
              </Button>
              <Button 
                mode="outlined"
                onPress={hideDeleteConfirmation}
                style={styles.cancelButton}
              >
                Cancel
              </Button>
            </View>
          </View>
        </Modal>
      </View>
    </PaperProvider>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
  },
  contentWrapper: {
    flex: 1,
    width: '100%',
    maxWidth: 400,
    justifyContent: 'space-between',
  },
  scrollViewContent: {
    flexGrow: 1,
    justifyContent: 'center',
  },
  content: {
    width: '100%',
    alignItems: 'center',
    backgroundColor: 'rgba(30, 30, 30, 0.8)',
    padding: 20,
    borderRadius: 10,
  },
  title: {
    fontSize: 32,
    marginBottom: 20,
    color: '#ffffff',
    textAlign: 'center',
  },
  userInfoContainer: {
    width: '100%',
    marginBottom: 20,
    padding: 10,
    backgroundColor: 'rgba(60, 60, 60, 0.5)',
    borderRadius: 5,
  },
  userInfoText: {
    color: '#ffffff',
    fontSize: 16,
    marginBottom: 5,
  },
  inputContainer: {
    width: '100%',
    marginBottom: 15,
  },
  inputLabel: {
    color: '#ffffff',
    marginBottom: 5,
    fontSize: 16,
  },
  input: {
    backgroundColor: 'rgba(44, 44, 44, 0.8)',
    color: '#ffffff',
    padding: 10,
    borderRadius: 5,
    fontSize: 16,
  },
  saveButton: {
    marginTop: 10,
    marginBottom: 20,
    backgroundColor: '#4caf50',
    width: '80%',
    borderRadius: 25,
  },
  deleteButton: {
    backgroundColor: '#f44336',
    width: '80%',
    borderRadius: 25,
  },
  buttonContent: {
    height: 50,
  },
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
  },
  modalContent: {
    backgroundColor: '#1E1E1E',
    padding: 20,
    borderRadius: 15,
    alignItems: 'center',
    width: '90%',
    maxWidth: 400,
    borderWidth: 1,
    borderColor: '#333333',
  },
  modalText: {
    color: '#ffffff',
    fontSize: 18,
    textAlign: 'center',
    marginBottom: 20,
  },
  loadingContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
  },
  loadingText: {
    color: '#ffffff',
    marginTop: 10,
    fontSize: 16,
  },
  disabledInput: {
    opacity: 0.5,
  },
  successMessage: {
    color: '#4CAF50',
    fontSize: 16,
    textAlign: 'center',
    marginBottom: 10,
  },
  warningMessage: {
    color: '#FFA500',
    fontSize: 16,
    textAlign: 'center',
    marginBottom: 10,
  },
  infoText: {
    color: '#ffffff',
    fontSize: 16,
    textAlign: 'center',
    marginBottom: 20,
  },
  verificationContainer: {
    width: '100%',
    alignItems: 'center',
    marginBottom: 20,
  },
  verificationButton: {
    marginTop: 10,
    backgroundColor: '#2196F3',
  },
  deleteConfirmButton: {
    marginTop: 20,
    width: '100%',
    backgroundColor: '#D32F2F',
  },
  deleteConfirmButtonLabel: {
    color: '#FFFFFF',
    fontSize: 16,
    fontWeight: 'bold',
  },
  cancelButton: {
    marginTop: 10,
    width: '100%',
  },
  charCount: {
    color: '#aaaaaa',
    fontSize: 12,
    alignSelf: 'flex-end',
    marginTop: 5,
  },
});

export default AccountManagement;