import React, { useState, useContext } from 'react';
import { View, StyleSheet, Platform } from 'react-native';
import { TextInput, Button, Title, Text, Portal, Modal, ActivityIndicator } from 'react-native-paper';
import { useNavigate, Link } from 'react-router-dom';
import { auth, db } from '../firebase';
import { signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { AppContext } from '../context/AppContext';
import BackgroundImage from './BackgroundImage';
import Footer from './Footer';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { setUser } = useContext(AppContext);

  const handleLogin = async () => {
    setLoading(true);
    setError('');
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email.toLowerCase(), password);
      setUser(userCredential.user);
      navigate('/');
    } catch (error) {
      setError('Invalid email or password. Please try again.');
      console.error('Error signing in:', error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleGoogleSignIn = async () => {
    setLoading(true);
    setError('');
    try {
      const provider = new GoogleAuthProvider();
      const userCredential = await signInWithPopup(auth, provider);
      const user = userCredential.user;
      
      const userEmail = user.email.toLowerCase(); // Convert email to lowercase
      const userDocRef = doc(db, 'users', userEmail);
      const userDocSnap = await getDoc(userDocRef);

      if (!userDocSnap.exists()) {
        await setDoc(userDocRef, {
          name: '',
          email: userEmail, // Use lowercase email
          description: '',
          createdAt: new Date(),
        });
        setUser(user);
        navigate('/account', { state: { newAccount: true } });
      } else {
        setUser(user);
        navigate('/');
      }
    } catch (error) {
      setError('Error signing in with Google. Please try again.');
      console.error('Error signing in with Google:', error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <View style={styles.container}>
      <BackgroundImage />
      <View style={styles.contentWrapper}>
        <View style={styles.content}>
          <Title style={styles.title}>Login</Title>
          <TextInput
            label="Email"
            value={email}
            onChangeText={setEmail}
            mode="outlined"
            style={styles.input}
            autoComplete="email"
            disabled={loading}
          />
          <TextInput
            label="Password"
            value={password}
            onChangeText={setPassword}
            secureTextEntry
            mode="outlined"
            style={styles.input}
            autoComplete="current-password"
            disabled={loading}
          />
          {error ? <Text style={styles.error}>{error}</Text> : null}
          <Text style={styles.agreementText}>
            By logging in or creating an account, you agree to our{' '}
            <Link to="/terms-of-service" style={styles.link}>Terms of Service</Link> and{' '}
            <Link to="/privacy-policy" style={styles.link}>Privacy Policy</Link>.
          </Text>
          <Button 
            mode="contained" 
            onPress={handleLogin} 
            style={styles.button}
            contentStyle={styles.buttonContent}
            disabled={loading}
          >
            Login
          </Button>
          <Button
            mode="contained"
            onPress={handleGoogleSignIn}
            style={[styles.button, styles.googleButton]}
            contentStyle={styles.buttonContent}
            disabled={loading}
          >
            Sign in with Google
          </Button>
          <Button
            mode="text"
            onPress={() => navigate('/create-account')}
            style={styles.textButton}
            disabled={loading}
          >
            Create Account
          </Button>
          <Button
            mode="text"
            onPress={() => navigate('/reset-password')}
            style={styles.textButton}
            disabled={loading}
          >
            Forgot Password?
          </Button>
        </View>
      </View>
      <View style={styles.footerWrapper}>
        <View style={styles.footerContent}>
          <Footer />
        </View>
      </View>
      <Portal>
        <Modal visible={loading} dismissable={false} contentContainerStyle={styles.modalContainer}>
          <ActivityIndicator size="large" color="#ffffff" />
          <Text style={styles.modalText}>Logging in...</Text>
        </Modal>
      </Portal>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  contentWrapper: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: 60, // Add space for footer
  },
  content: {
    width: '100%',
    maxWidth: 400,
    alignItems: 'center',
    backgroundColor: 'rgba(30, 30, 30, 0.8)',
    padding: 20,
    borderRadius: 10,
  },
  title: {
    fontSize: 32,
    marginBottom: 20,
    color: '#ffffff',
    textAlign: 'center',
  },
  input: {
    marginBottom: 12,
    width: '100%',
    backgroundColor: '#2c2c2c',
  },
  button: {
    marginTop: 20,
    width: '80%',
    borderRadius: 25,
  },
  buttonContent: {
    height: 50,
  },
  textButton: {
    marginTop: 15,
  },
  googleButton: {
    marginTop: 10,
    backgroundColor: '#4285F4', // Google's blue color
  },
  error: {
    color: '#ff6b6b',
    marginBottom: 12,
    textAlign: 'center',
  },
  agreementText: {
    color: '#e0e0e0',
    textAlign: 'center',
    marginTop: 10,
    marginBottom: 10,
    fontSize: 12,
  },
  link: {
    color: '#BB86FC',
    textDecorationLine: 'underline',
  },
  footerWrapper: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    alignItems: 'center',
    paddingBottom: 10,
  },
  footerContent: {
    width: '100%',
    maxWidth: 400,
  },
  modalContainer: {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    padding: 20,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10,
  },
  modalText: {
    color: '#ffffff',
    marginTop: 10,
    fontSize: 16,
  },
});

export default Login;