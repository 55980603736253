import React, { useContext, useEffect } from 'react';
import { View, StyleSheet, Linking } from 'react-native';
import { Button, Text, Title } from 'react-native-paper';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../context/AppContext';
import BackgroundImage from './BackgroundImage';
import Footer from './Footer';

function Home() {
  const navigate = useNavigate();
  const { user, fetchAndCacheCharacters, logout, refetchSubscription } = useContext(AppContext);

  useEffect(() => {
    if (user) {
      fetchAndCacheCharacters();
      refetchSubscription();
    }
  }, [user]); // Remove fetchAndCacheCharacters and refetchSubscription from the dependency array

  const handleLogout = async () => {
    try {
      await logout();
    } catch (error) {
      console.error('Error signing out:', error.message);
    }
  };

  return (
    <View style={styles.container}>
      <BackgroundImage />
      <View style={styles.contentWrapper}>
        <View style={styles.centeringWrapper}>
          <View style={styles.content}>
            <Title style={styles.title}>Welcome to Fur Chat AI</Title>
            <Text style={styles.intro}>
              Fur Chat AI is a visually interactive furry chatbot site.<br />
              New characters are being added all the time and updates are being posted regularly to the <a href="https://www.patreon.com/FurChatAI" style={{color: '#ffffff', textDecoration: 'underline'}}>patreon</a> page.<br />
              Daily free messages are limited. Subscriptions are currently available via <a href="https://www.patreon.com/FurChatAI" style={{color: '#ffffff', textDecoration: 'underline'}}>patreon</a>.
            </Text>
            {user ? (
              <View style={styles.buttonContainer}>
                <Button 
                  mode="contained" 
                  onPress={() => navigate('/chat-characters')} 
                  style={styles.button}
                  contentStyle={styles.buttonContent}
                >
                  Chat with Characters
                </Button>
                <Button 
                  mode="contained" 
                  onPress={() => navigate('/account')} 
                  style={styles.button}
                  contentStyle={styles.buttonContent}
                >
                  Manage Account
                </Button>
                <Button 
                  mode="contained" 
                  onPress={() => Linking.openURL('https://discord.gg/HcwQqsb6nG')} 
                  style={styles.button}
                  contentStyle={styles.buttonContent}
                >
                  Join Discord Server
                </Button>
                <Button 
                  mode="contained" 
                  onPress={handleLogout} 
                  style={styles.button}
                  contentStyle={styles.buttonContent}
                >
                  Logout
                </Button>
              </View>
            ) : (
              <View style={styles.buttonContainer}>
                <Button 
                  mode="contained" 
                  onPress={() => navigate('/chat-characters')} 
                  style={styles.button}
                  contentStyle={styles.buttonContent}
                >
                  Chat with Characters
                </Button>
                <Button 
                  mode="contained" 
                  onPress={() => Linking.openURL('https://discord.gg/HcwQqsb6nG')} 
                  style={styles.button}
                  contentStyle={styles.buttonContent}
                >
                  Join Discord Server
                </Button>
                <Button 
                  mode="contained" 
                  onPress={() => navigate('/login')} 
                  style={styles.button}
                  contentStyle={styles.buttonContent}
                >
                  Login
                </Button>
              </View>
            )}
          </View>
        </View>
        <Footer />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
  },
  contentWrapper: {
    flex: 1,
    width: '100%',
    maxWidth: 400,
    justifyContent: 'space-between',
  },
  centeringWrapper: {
    flex: 1,
    justifyContent: 'center',
  },
  content: {
    width: '100%',
    alignItems: 'center',
    backgroundColor: 'rgba(30, 30, 30, 0.8)',
    padding: 20,
    borderRadius: 10,
  },
  title: {
    fontSize: 32,
    marginBottom: 20,
    color: '#ffffff',
    textAlign: 'center',
  },
  intro: {
    marginBottom: 30,
    textAlign: 'left',
    color: '#e0e0e0',
    lineHeight: 24,
  },
  buttonContainer: {
    width: '100%',
    alignItems: 'center',
  },
  button: {
    marginTop: 15,
    width: '80%',
    borderRadius: 25,
  },
  buttonContent: {
    height: 50,
  },
});

export default Home;