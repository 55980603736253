import React, { useEffect, useState, useRef, useCallback } from 'react';
import { View, StyleSheet, Dimensions } from 'react-native';
import { useBackgroundImage } from './BackgroundImageContext';

const defaultImages = [
  'https://furgenai.b-cdn.net/female_tiger_sasha/0302af53-3135-4b63-bd41-7dab968b7c67.webp',
  'https://furgenai.b-cdn.net/female_tiger_sasha/5dc519df-222a-4224-9112-2835ced2f212.webp',
  'https://furgenai.b-cdn.net/female_tiger_sasha/2944dd92-5c7b-48b3-8f6b-4cc70de03604.webp',
  'https://furgenai.b-cdn.net/female_fennec_riff/7351bb75-c907-4faa-9607-e7cb92385238.webp',
  'https://furgenai.b-cdn.net/female_fennec_riff/aece81bf-55d0-4604-ba4d-0d593a1605af.webp',
  'https://furgenai.b-cdn.net/female_gsd_gemma/2026d437-7800-4e77-be7b-bab4e191383b.webp',
  'https://furgenai.b-cdn.net/female_husky_natalia/18ba2e94-3095-4f29-a323-754d78798d52.webp',
  'https://furgenai.b-cdn.net/female_husky_natalia/35387947-f06e-4171-b403-ccdfdcc89e16.webp',
  'https://furgenai.b-cdn.net/female_zebra_lilly/1697b9ae-faff-4a4f-bd6a-a2fd8c82c48b.webp',
  'https://furgenai.b-cdn.net/female_zebra_lilly/268dfbcc-3230-4e63-a319-ed7f4d3ac7be.webp'
];

const shuffleArray = (array) => {
  const shuffled = [...array];
  for (let i = shuffled.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
  }
  return shuffled;
};

const BackgroundImage = ({ images }) => {
  const { updateBackgroundImages, isMobile } = useBackgroundImage();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [nextIndex, setNextIndex] = useState(1);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [opacity, setOpacity] = useState(1);
  const [loadedImages, setLoadedImages] = useState([]);
  const [dimensions, setDimensions] = useState(Dimensions.get('window'));
  const [isFirstImageLoaded, setIsFirstImageLoaded] = useState(false);
  
  const imagesRef = useRef(images && images.length > 0 ? [...images] : shuffleArray(defaultImages));

  useEffect(() => {
    const updateDimensions = () => {
      setDimensions(Dimensions.get('window'));
    };

    Dimensions.addEventListener('change', updateDimensions);

    return () => {
      Dimensions.removeEventListener('change', updateDimensions);
    };
  }, []);

  const preloadImages = useCallback((imagesToLoad) => {
    const loadImage = (src) => new Promise((resolve, reject) => {
      const img = new window.Image();
      img.src = src;
      img.onload = () => resolve(src);
      img.onerror = reject;
    });

    // Load the first image immediately
    loadImage(imagesToLoad[0])
      .then((loadedSrc) => {
        setLoadedImages([loadedSrc]);
        setIsFirstImageLoaded(true);
        //console.log('First image loaded');
      })
      .catch((error) => console.error('Error loading first image:', error));

    // Preload the rest of the images in the background
    Promise.all(imagesToLoad.slice(1).map(loadImage))
      .then((loadedSrcs) => {
        //console.log('All remaining images preloaded');
        setLoadedImages(prev => [...prev, ...loadedSrcs]);
      })
      .catch((error) => console.error('Error preloading remaining images:', error));
  }, []);

  useEffect(() => {
    const imagesToUse = images && images.length > 0 ? images : shuffleArray(defaultImages);
    imagesRef.current = imagesToUse;
    preloadImages(imagesToUse);
  }, [images, preloadImages]);

  useEffect(() => {
    if (loadedImages.length > 0) {
      updateBackgroundImages(loadedImages);
      //console.log('Current images:', loadedImages);
    }
  }, [loadedImages, updateBackgroundImages]);

  const fadeToNextImage = useCallback(() => {
    if (isTransitioning || imagesRef.current.length < 2) return;

    setIsTransitioning(true);
    //console.log('Fading to next image');
    //console.log('Current index before fade:', currentIndex);
    //console.log('Next index before fade:', nextIndex);

    let progress = 0;
    const fadeInterval = setInterval(() => {
      progress += 0.02;
      if (progress >= 1) {
        clearInterval(fadeInterval);
        setCurrentIndex(nextIndex);
        setNextIndex((prevNextIndex) => (prevNextIndex + 1) % imagesRef.current.length);
        setOpacity(1);
        setIsTransitioning(false);
        //console.log('Fade complete');
        //console.log('New current index:', nextIndex);
        //console.log('New next index:', (nextIndex + 1) % imagesRef.current.length);
      } else {
        setOpacity(1 - progress);
      }
    }, 20);
  }, [currentIndex, nextIndex, isTransitioning]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      //console.log('Interval triggered');
      fadeToNextImage();
    }, 15000);

    return () => {
      //console.log('Clearing interval');
      clearInterval(intervalId);
    };
  }, [fadeToNextImage]);
  
  if (!isFirstImageLoaded) {
    return null; // or a loading indicator
  }

  const foregroundImageStyle = isMobile
    ? { ...styles.foregroundImage, top: 0, height: dimensions.width }
    : styles.foregroundImage;

  return (
    <View style={styles.backgroundContainer}>
      <div style={styles.blurredBackgroundContainer}>
        <div
          style={{
            ...styles.blurredBackground,
            backgroundImage: `url(${imagesRef.current[nextIndex]})`,
            opacity: 1 - opacity,
          }}
        />
        <div
          style={{
            ...styles.blurredBackground,
            backgroundImage: `url(${imagesRef.current[currentIndex]})`,
            opacity: opacity,
          }}
        />
      </div>
      <div
        style={{
          ...foregroundImageStyle,
          backgroundImage: `url(${imagesRef.current[nextIndex]})`,
          opacity: 1 - opacity,
        }}
      />
      <div
        style={{
          ...foregroundImageStyle,
          backgroundImage: `url(${imagesRef.current[currentIndex]})`,
          opacity: opacity,
        }}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  backgroundContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflow: 'hidden',
  },
  blurredBackgroundContainer: {
    position: 'absolute',
    top: '-5%',
    left: '-5%',
    right: '-5%',
    bottom: '-5%',
    overflow: 'hidden',
  },
  blurredBackground: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    filter: 'blur(20px)',
    transform: 'scale(1.1)',
  },
  foregroundImage: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    zIndex: 1,
  },
});

export default BackgroundImage;