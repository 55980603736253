import React from 'react';
import { View, StyleSheet, ScrollView, Linking } from 'react-native';
import { Text, Title, Card } from 'react-native-paper';
import BackgroundImage from './BackgroundImage';
import Footer from './Footer';

const FAQItem = ({ question, answer }) => (
  <Card style={styles.card}>
    <Card.Content>
      <Text style={styles.question}>{question}</Text>
      <Text style={styles.answer}>
        {answer.split('Discord').map((part, index, array) => 
          index === array.length - 1 ? part : (
            <React.Fragment key={index}>
              {part}
              <Text style={styles.link} onPress={() => Linking.openURL('https://discord.gg/HcwQqsb6nG')}>
                Discord
              </Text>
            </React.Fragment>
          )
        )}
      </Text>
    </Card.Content>
  </Card>
);

function FAQ() {
  const faqItems = [
    {
      question: "Are my chat messages saved on the server?",
      answer: "No, chat messages are not saved on the servers. In-progress chat sessions are only saved locally on your device."
    },
    {
      question: "I'm having a problem, help?!",
      answer: "Please direct all support inquiries to the support channel in the Discord server."
    },
    {
      question: "Will you be accepting other payment methods for subscriptions?",
      answer: "Yes, payment processing for adult content is tricky but more payment options are coming soon including cryptocurrency payments."
    }
  ];

  return (
    <View style={styles.container}>
      <BackgroundImage />
      <View style={styles.contentWrapper}>
        <ScrollView contentContainerStyle={styles.scrollView}>
          <View style={styles.faqContent}>
            <Title style={styles.title}>Frequently Asked Questions</Title>
            {faqItems.map((item, index) => (
              <FAQItem key={index} question={item.question} answer={item.answer} />
            ))}
          </View>
        </ScrollView>
      </View>
      <View style={styles.footerWrapper}>
        <View style={styles.footerContent}>
          <Footer />
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
  },
  contentWrapper: {
    flex: 1,
    width: '100%',
    maxWidth: 600,
    justifyContent: 'center',
  },
  scrollView: {
    flexGrow: 1,
    justifyContent: 'center',
  },
  faqContent: {
    width: '100%',
    backgroundColor: 'rgba(30, 30, 30, 0.8)',
    padding: 20,
    borderRadius: 10,
  },
  title: {
    fontSize: 32,
    marginBottom: 20,
    color: '#ffffff',
    textAlign: 'center',
  },
  card: {
    marginBottom: 15,
    backgroundColor: 'rgba(40, 40, 40, 0.9)',
  },
  question: {
    color: '#ffffff',
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  answer: {
    color: '#e0e0e0',
    fontSize: 16,
  },
  link: {
    color: '#4a9eff',
    textDecorationLine: 'underline',
  },
  footerWrapper: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    alignItems: 'center',
    paddingBottom: 10,
  },
  footerContent: {
    width: '100%',
    maxWidth: 400,
  },
});

export default FAQ;