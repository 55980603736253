import React, { useState, useContext, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { Provider as PaperProvider, DefaultTheme } from 'react-native-paper';
import { AppContextProvider, AppContext } from './context/AppContext';
import Home from './components/Home';
import Login from './components/Login';
import AccountManagement from './components/AccountManagement';
import AccountCreation from './components/AccountCreation';
import PasswordReset from './components/PasswordReset';
import ChatCharacterSelection from './components/ChatCharacterSelection';
import ChatScreen from './components/ChatScreen';
import FAQ from './components/FAQ';
import Contact from './components/Contact';
import AgeVerification from './components/AgeVerification';
import TermsOfService from './components/TermsOfService';
import PrivacyPolicy from './components/PrivacyPolicy';
import { View, StyleSheet } from 'react-native';
import { BackgroundImageProvider } from './components/BackgroundImageContext';

const darkTheme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    primary: '#BB86FC',
    accent: '#03DAC6',
    background: '#121212',
    surface: '#121212',
    text: '#FFFFFF',
  },
};

function AppContent() {
  const { user } = useContext(AppContext);
  const [isAgeVerified, setIsAgeVerified] = useState(() => {
    return localStorage.getItem('isAgeVerified') === 'true';
  });

  useEffect(() => {
    localStorage.setItem('isAgeVerified', isAgeVerified);
  }, [isAgeVerified]);

  return (
    <Routes>
      {!isAgeVerified ? (
        <Route path="*" element={<AgeVerification onVerified={() => setIsAgeVerified(true)} />} />
      ) : (
        <>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/account" element={<AccountManagement />} />
          <Route path="/create-account" element={<AccountCreation />} />
          <Route path="/chat-characters" element={<ChatCharacterSelection />} />
          <Route path="/reset-password" element={<PasswordReset />} />
          <Route path="/chat/:characterId/:sceneIndex" element={<ChatScreen />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </>
      )}
    </Routes>
  );
}

function App() {
  return (
    <Router>
      <View style={styles.container}>
        <BackgroundImageProvider>
          <AppContextProvider>
            <PaperProvider theme={darkTheme}>
              <AppContent />
            </PaperProvider>
          </AppContextProvider>
        </BackgroundImageProvider>
      </View>
    </Router>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    height: '100%',
    width: '100%',
  },
});

export default App;