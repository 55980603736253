import React, { createContext, useState, useEffect, useCallback } from 'react';
import { auth, db } from '../firebase';
import { onAuthStateChanged, signOut, getAuth, setPersistence, browserLocalPersistence } from 'firebase/auth';
import { collection, getDocs, doc, getDoc } from 'firebase/firestore';
import { useSubscription } from '../components/UserSubscription';

export const AppContext = createContext();

const CHARACTERS_CACHE_KEY = 'chatCharactersCache';
const CHARACTERS_CACHE_EXPIRY = 6 * 60 * 60 * 1000; // 6 hours in milliseconds

export const AppContextProvider = ({ children }) => {
  const [user, setUser] = useState(() => auth.currentUser);
  const [chatCharacters, setChatCharacters] = useState(null);
  const [isCharactersLoading, setIsCharactersLoading] = useState(true);
  const { subscriptionStatus, isLoading: isSubscriptionLoading, refetch: refetchSubscription } = useSubscription();
  const [characterOverrides, setCharacterOverrides] = useState({});
  const [isOverridesLoading, setIsOverridesLoading] = useState(true);
  const [isDataReady, setIsDataReady] = useState(false);

  const fetchAndCacheCharacters = useCallback(async (forceUpdate = false) => {
    const cachedData = localStorage.getItem(CHARACTERS_CACHE_KEY);
    if (cachedData && !forceUpdate) {
      const { data, timestamp } = JSON.parse(cachedData);
      setChatCharacters(data);
      setIsCharactersLoading(false);

      // If cache is expired, update in background
      if (Date.now() - timestamp >= CHARACTERS_CACHE_EXPIRY) {
        fetchAndCacheCharacters(true);
      }
      return;
    }

    try {
      const querySnapshot = await getDocs(collection(db, 'chat_characters'));
      const charactersData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setChatCharacters(charactersData);
      localStorage.setItem(CHARACTERS_CACHE_KEY, JSON.stringify({
        data: charactersData,
        timestamp: Date.now()
      }));
    } catch (error) {
      console.error('Error fetching characters:', error);
    } finally {
      setIsCharactersLoading(false);
      checkDataReady();
    }
  }, []);

  const fetchCharacterOverrides = useCallback(async () => {
    if (user) {
      setIsOverridesLoading(true);
      const userDocRef = doc(db, 'users', user.email.toLowerCase());
      try {
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setCharacterOverrides(userData.character_customizations || {});
        }
      } catch (error) {
        console.error('Error fetching character overrides:', error);
      } finally {
        setIsOverridesLoading(false);
        checkDataReady();
      }
    } else {
      setCharacterOverrides({});
      setIsOverridesLoading(false);
      checkDataReady();
    }
  }, [user]);

  const checkDataReady = useCallback(() => {
    if (!isCharactersLoading && !isOverridesLoading) {
      setIsDataReady(true);
    }
  }, [isCharactersLoading, isOverridesLoading]);

  useEffect(() => {
    fetchAndCacheCharacters();
    fetchCharacterOverrides();
  }, [fetchAndCacheCharacters, fetchCharacterOverrides]);

  const handleAuthStateChange = useCallback((currentUser) => {
    setUser(currentUser);
    // Remove the refetchSubscription call from here
  }, []);

  useEffect(() => {
    const initAuth = async () => {
      await setPersistence(getAuth(), browserLocalPersistence);
      const unsubscribe = onAuthStateChanged(auth, handleAuthStateChange);
      return unsubscribe;
    };

    initAuth();
  }, [handleAuthStateChange]);

  const logout = async () => {
    try {
      await signOut(auth);
      localStorage.removeItem('subscriptionStatusCache');
      // The handleAuthStateChange function will automatically set the user to null
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const contextValue = {
    user,
    setUser,
    subscriptionStatus,
    isSubscriptionLoading,
    refetchSubscription,
    chatCharacters,
    isCharactersLoading,
    fetchAndCacheCharacters: () => fetchAndCacheCharacters(true),
    logout,
    characterOverrides,
    setCharacterOverrides,
    isOverridesLoading,
    fetchCharacterOverrides,
    isDataReady
  };

  return (
    <AppContext.Provider value={contextValue}>
      {children}
    </AppContext.Provider>
  );
};