import React from 'react';
import { View, StyleSheet, ScrollView } from 'react-native';
import { Text, Title, Subheading } from 'react-native-paper';
import BackgroundImage from './BackgroundImage';
import Footer from './Footer';

function TermsOfService() {
    return (
      <View style={styles.container}>
        <BackgroundImage />
        <View style={styles.content}>
          <ScrollView contentContainerStyle={styles.scrollView}>
            <View style={styles.textContent}>
              <Title style={styles.title}>Terms of Service</Title>
                <Subheading style={styles.subheading}>General Information Regarding These Terms of Use</Subheading>
                <Text style={styles.text}>
                These terms of use and any other terms and conditions that may accompany the materials made through this website (collectively, the "Terms") apply to all furchat.ai webpages (collectively, the "Websites"). The Terms also apply to all information and services provided by FurChatAI through the Websites (together with the Websites, the "Services"). Services offered are provided subject to these Terms, the FurChatAI Privacy Policy (available on the FurChatAI Websites), and any additional terms specified on the relevant Website(s) or provided when Services are obtained, all of which are hereby incorporated by reference into these Terms.
                </Text>

                <Subheading style={styles.subheading}>Use of the furchat.ai Site</Subheading>
                <Text style={styles.text}>
                FurChatAI is committed to ensuring a secure experience for everyone that accesses or uses the Websites. In order to achieve this, there are basic rules you must follow. You may use the Websites only for lawful purposes and in accordance with these Terms. Additionally, You agree not to:
                </Text>
                <Text style={styles.text}>
                • Use the Website or Services in any way that violates any applicable federal, state, local, or international law or regulation.
                </Text>
                <Text style={styles.text}>
                • Exploit, harm, or attempt to exploit or harm minors in any way.
                </Text>
                <Text style={styles.text}>
                • Violate copyright, trademark, or other intellectual property laws.
                </Text>
                <Text style={styles.text}>
                • Distribute unwanted, unsolicited, or harassing mass email or other messages, promotions, advertising, or solicitations ("spam").
                </Text>
                <Text style={styles.text}>
                • Attack, abuse, interfere with, intercept, disrupt, or exploit any users, systems, or services.
                </Text>
                <Text style={styles.text}>
                • Attempt to collect, store, or publish personally identifiable information without the owner's knowledge and consent.
                </Text>
                <Text style={styles.text}>
                • Post or transmit content that is harmful, offensive, obscene, abusive, invasive of privacy, defamatory, hateful or otherwise discriminatory, false or misleading, or incites an illegal act.
                </Text>
                <Text style={styles.text}>
                • Impersonate another person or entity or use or attempt to use another's account or personal information without authorization.
                </Text>

                <Subheading style={styles.subheading}>Changes to the Terms</Subheading>
                <Text style={styles.text}>
                We may revise and update these Terms from time to time in our sole discretion. We will provide you at least 30 days advance notice for any material change to these Terms. All other changes are effective immediately when we post them. Your continued use of any Website or Services following the posting of revised Terms means that you accept and agree to the changes.
                </Text>

                <Subheading style={styles.subheading}>Content Available through the Services</Subheading>
                <Text style={styles.text}>
                FurChatAI attempts to provide accurate information on its Websites. However, we take no responsibility for the accuracy of the information, content or materials which you may have access to as part of, or through your use of the Websites or Services. All information, content and material are provided as-is.
                </Text>

                <Subheading style={styles.subheading}>Registered Users</Subheading>
                <Text style={styles.text}>
                Registration: You must be 18 years of age or older to register for an account. You agree to (a) only provide accurate and current information about yourself, (b) maintain the security of your passwords and identification, (c) promptly update the email address listed in connection with your account to keep it accurate so that we can contact you, and (d) be fully responsible for all uses of your account. You must not set up an account on behalf of another individual or entity unless you are authorized to do so.
                </Text>
                <Text style={styles.text}>
                Termination: FurChatAI reserves the right to modify or discontinue your account at any time for any reason or no reason at all.
                </Text>

                <Subheading style={styles.subheading}>Disclaimer of Warranties</Subheading>
                <Text style={styles.text}>
                TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, FurChatAI OFFERS THE SERVICES (INCLUDING ALL CONTENT AVAILABLE ON OR THROUGH THE SERVICES) AS-IS AND MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND CONCERNING THE WEBSITES OR SERVICES, EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING WITHOUT LIMITATION, WARRANTIES OF TITLE, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT.
                </Text>

                <Subheading style={styles.subheading}>Limitation of Liability</Subheading>
                <Text style={styles.text}>
                NEITHER FurChatAI NOR ITS SUPPLIERS WILL BE LIABLE TO YOU ON ANY LEGAL THEORY FOR ANY INCIDENTAL, DIRECT, INDIRECT, PUNITIVE, ACTUAL, CONSEQUENTIAL, SPECIAL, EXEMPLARY, OR OTHER DAMAGES, INCLUDING WITHOUT LIMITATION, LOSS OF REVENUE OR INCOME, LOST PROFITS, PAIN AND SUFFERING, EMOTIONAL DISTRESS, COST OF SUBSTITUTE GOODS OR SERVICES, OR SIMILAR DAMAGES SUFFERED OR INCURRED BY YOU OR ANY THIRD PARTY THAT ARISE IN CONNECTION WITH THE WEBSITES OR SERVICES (OR THE TERMINATION THEREOF FOR ANY REASON), EVEN IF FurChatAI HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
                </Text>

                <Subheading style={styles.subheading}>Indemnification</Subheading>
                <Text style={styles.text}>
                To the extent authorized by law, you agree to indemnify and hold harmless FurChatAI, its employees, officers, directors, affiliates, and agents from and against any and all claims, losses, expenses, damages, and costs, including reasonable attorneys' fees, incurred in connection with any claim arising out of your breach of these Terms.
                </Text>

                <Subheading style={styles.subheading}>Privacy Policy</Subheading>
                <Text style={styles.text}>
                FurChatAI is committed to responsibly handling the information and data we collect through our Services in compliance with our Privacy Policy, which is incorporated by reference into these Terms. Please review FurChatAI's Privacy Policy so you are aware of how we collect and use your personal information.
                </Text>

                <Subheading style={styles.subheading}>Termination</Subheading>
                <Text style={styles.text}>
                We may, in our sole discretion, (i) modify, suspend, or terminate your access to any or all of the Website, or (ii) cease to provide and maintain the Website, at any time, for any or no reason, with or without prior notice, and without liability. Your right to access and use the Websites and Services will be automatically terminated if you violate any of the Terms.
                </Text>

                <Subheading style={styles.subheading}>Miscellaneous Terms</Subheading>
                <Text style={styles.text}>
                The Terms are governed by and construed by the laws of the State of Nevada in the United States of America, not including its choice of law rules. Use of the Services is unauthorized in any jurisdiction that does not give effect to all provisions of these terms and conditions, including without limitation this paragraph.
                </Text>
                <Text style={styles.text}>
                If any provision of these Terms is found to be invalid or unenforceable, that provision will be struck and the remaining provisions will remain in full effect.
                </Text>
                <Text style={styles.text}>
                If you or others violate these Terms and we take no immediate action, this in no way limits or waives our rights, such as our right to take action in the future or in similar situations.
              </Text>
          </View>
        </ScrollView>
      </View>
      <View style={styles.footerWrapper}>
        <View style={styles.footerContent}>
          <Footer />
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  content: {
    flex: 1,
    marginBottom: 60, // Increased to account for footer padding
  },
  scrollView: {
    flexGrow: 1,
    justifyContent: 'flex-start',
    padding: 20,
  },
  textContent: {
    backgroundColor: 'rgba(30, 30, 30, 0.8)',
    padding: 20,
    borderRadius: 10,
    maxWidth: 800,
    width: '100%',
    alignSelf: 'center',
  },
  title: {
    fontSize: 32,
    marginBottom: 20,
    color: '#ffffff',
    textAlign: 'center',
  },
  subheading: {
    fontSize: 20,
    marginTop: 15,
    marginBottom: 10,
    color: '#ffffff',
  },
  text: {
    color: '#e0e0e0',
    marginBottom: 10,
  },
  footerWrapper: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    alignItems: 'center',
    paddingBottom: 10,
  },
  footerContent: {
    width: '100%',
    maxWidth: 400,
  },
});

export default TermsOfService;