import React, { useState, useContext } from 'react';
import { View, StyleSheet, ScrollView, Dimensions } from 'react-native';
import { TextInput, Button, Title, Text, useTheme } from 'react-native-paper';
import { useNavigate, Link } from 'react-router-dom';
import { auth, db } from '../firebase';
import { createUserWithEmailAndPassword, sendEmailVerification } from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import { AppContext } from '../context/AppContext';
import BackgroundImage from './BackgroundImage';

const { width, height } = Dimensions.get('window');

function AccountCreation() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [verificationSent, setVerificationSent] = useState(false);
  const navigate = useNavigate();
  const { setUser } = useContext(AppContext);
  const theme = useTheme();

  const validateForm = () => {
    if (!email || !password || !confirmPassword) {
      setError('All fields are required.');
      return false;
    }
    if (password !== confirmPassword) {
      setError('Passwords do not match.');
      return false;
    }
    if (password.length < 6) {
      setError('Password must be at least 6 characters long.');
      return false;
    }
    return true;
  };

  const handleCreateAccount = async () => {
    if (!validateForm()) return;
  
    try {
      const lowerCaseEmail = email.toLowerCase();
      const userCredential = await createUserWithEmailAndPassword(auth, lowerCaseEmail, password);
      await setDoc(doc(db, 'users', lowerCaseEmail), {
        uid: userCredential.user.uid,
        email: lowerCaseEmail,
      });
      
      // Send email verification
      await sendEmailVerification(userCredential.user);
      setVerificationSent(true);
      
      setUser(userCredential.user);
      
      // Navigate to AccountManagement with a state indicating new account creation
      navigate('/account', { state: { newAccount: true, emailVerificationSent: true } });
    } catch (error) {
      setError('Failed to create account. Please try again.');
      console.error('Error creating account:', error.message);
    }
  };

  return (
    <View style={styles.container}>
      <BackgroundImage />
      <ScrollView contentContainerStyle={styles.scrollViewContent}>
        <View style={styles.content}>
          <Title style={styles.title}>Create Account</Title>
          {verificationSent ? (
            <View>
              <Text style={styles.verificationText}>
                A verification email has been sent to your email address. 
                Please verify your email before logging in.
              </Text>
              <Button
                mode="contained"
                onPress={() => navigate('/login')}
                style={styles.button}
              >
                Go to Login
              </Button>
            </View>
          ) : (
            <>
              <Text style={styles.instructions}>
                Please fill out the form below to create your account. All fields are required.
              </Text>
              <TextInput
                label="Email"
                value={email}
                onChangeText={setEmail}
                mode="outlined"
                style={styles.input}
                autoComplete="email"
                outlineColor="rgba(255, 255, 255, 0.5)"
                activeOutlineColor="#bb86fc"
                theme={{
                  colors: {
                    text: 'white',
                    primary: '#bb86fc',
                    placeholder: 'rgba(255, 255, 255, 0.7)',
                  },
                }}
              />
              <TextInput
                label="Password"
                value={password}
                onChangeText={setPassword}
                secureTextEntry
                mode="outlined"
                style={styles.input}
                autoComplete="new-password"
                outlineColor="rgba(255, 255, 255, 0.5)"
                activeOutlineColor="#bb86fc"
                theme={{
                  colors: {
                    text: 'white',
                    primary: '#bb86fc',
                    placeholder: 'rgba(255, 255, 255, 0.7)',
                  },
                }}
              />
              <TextInput
                label="Confirm Password"
                value={confirmPassword}
                onChangeText={setConfirmPassword}
                secureTextEntry
                mode="outlined"
                style={styles.input}
                autoComplete="new-password"
                outlineColor="rgba(255, 255, 255, 0.5)"
                activeOutlineColor="#bb86fc"
                theme={{
                  colors: {
                    text: 'white',
                    primary: '#bb86fc',
                    placeholder: 'rgba(255, 255, 255, 0.7)',
                  },
                }}
              />
              <View style={styles.agreementContainer}>
                <Text style={styles.agreementText}>
                  By logging in or creating an account, you agree to our{' '}
                  <Link to="/terms-of-service" style={styles.link}>Terms of Service</Link> and{' '}
                  <Link to="/privacy-policy" style={styles.link}>Privacy Policy</Link>.
                </Text>
              </View>
              {error ? <Text style={styles.error}>{error}</Text> : null}
              <Button 
                mode="contained" 
                onPress={handleCreateAccount} 
                style={styles.button}
                contentStyle={styles.buttonContent}
              >
                Create Account
              </Button>
              <Button
                mode="text"
                onPress={() => navigate('/login')}
                style={styles.textButton}
                labelStyle={styles.textButtonLabel}
              >
                Already have an account? Login
              </Button>
            </>
          )}
        </View>
      </ScrollView>
      <style jsx global>{`
        .react-native-paper__input__label {
          color: white !important;
        }
        .react-native-paper__input__label--focused {
          color: #bb86fc !important;
        }
        .react-native-paper__input__text-input {
          color: white !important;
        }
        input {
          color: white !important;
        }
      `}</style>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  scrollViewContent: {
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 40,
  },
  content: {
    width: '100%',
    maxWidth: 460,
    alignItems: 'center',
    backgroundColor: 'rgba(30, 30, 30, 0.9)',
    padding: 30,
    borderRadius: 15,
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08)',
  },
  title: {
    fontSize: 36,
    fontWeight: 'bold',
    marginBottom: 24,
    color: '#ffffff',
    textAlign: 'center',
  },
  instructions: {
    color: '#ffffff',
    marginBottom: 24,
    textAlign: 'center',
    fontSize: 16,
  },
  input: {
    marginBottom: 16,
    width: '100%',
    backgroundColor: 'rgba(50, 50, 50, 0.5)',
  },
  agreementContainer: {
    marginBottom: 20,
    backgroundColor: 'rgba(79, 195, 247, 0.1)',
    borderRadius: 8,
    padding: 12,
  },
  agreementText: {
    color: '#ffffff',
    textAlign: 'center',
    fontSize: 14,
  },
  link: {
    color: '#bb86fc', // Light purple color
    textDecorationLine: 'underline',
  },
  button: {
    marginTop: 24,
    width: '100%',
    borderRadius: 25,
    backgroundColor: '#bb86fc', // Light purple color
  },
  buttonContent: {
    height: 50,
  },
  textButton: {
    marginTop: 16,
  },
  textButtonLabel: {
    color: '#bb86fc', // Light purple color
  },
  error: {
    color: '#ff6b6b',
    marginBottom: 16,
    textAlign: 'center',
  },
  verificationText: {
    color: '#ffffff',
    marginBottom: 24,
    textAlign: 'center',
    fontSize: 16,
  },
});

export default AccountCreation;