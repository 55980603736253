import React, { useContext } from 'react';
import { View, StyleSheet } from 'react-native';
import { Button, useTheme } from 'react-native-paper';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../context/AppContext';

function Footer() {
  const navigate = useNavigate();
  const { user } = useContext(AppContext);
  const theme = useTheme();

  const buttonColor = '#ffffff'; // Set a consistent white color for all footer text

  return (
    <View style={styles.footer}>
      <Button 
        mode="text" 
        onPress={() => navigate('/')} 
        style={styles.footerButton}
        color={buttonColor}
      >
        Home
      </Button>
      <Button 
        mode="text" 
        onPress={() => navigate('/chat-characters')} 
        style={styles.footerButton}
        color={buttonColor}
      >
        Chat
      </Button>
      {user ? (
        <Button 
          mode="text" 
          onPress={() => navigate('/account')} 
          style={styles.footerButton}
          color={buttonColor}
        >
          Settings
        </Button>
      ) : (
        <Button 
          mode="text" 
          onPress={() => navigate('/login')} 
          style={styles.footerButton}
          color={buttonColor}
        >
          Login
        </Button>
      )}
      <Button 
        mode="text" 
        onPress={() => navigate('/faq')} 
        style={styles.footerButton}
        color={buttonColor}
      >
        FAQ
      </Button>
      <Button 
        mode="text" 
        onPress={() => navigate('/contact')} 
        style={styles.footerButton}
        color={buttonColor}
      >
        Contact
      </Button>
    </View>
  );
}

const styles = StyleSheet.create({
  footer: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-around',
    backgroundColor: 'rgba(30, 30, 30, 0.8)',
    padding: 0,
    borderRadius: 10,
    marginTop: 20,
  },
  footerButton: {
    flex: 1,
  },
});

export default Footer;